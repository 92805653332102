'use strict';

/**
 * Gets the current page breadcrumb path
 *
 * @returns {string} - The breadcrumb path;
 */
function getBreadCrumbPath() {
    return $('.breadcrumb > li:not(.ignoreGDDL) span').map(function () {
        return $(this).text();
    }).get().join('/');
}

/**
 * Cleans an object of all undefined properties.
 * @param {Object} obj - The object to clean.
 *
 * @return {Object} - The cleaned object.
 */
function cleanObject(obj) {
    if (!obj) return obj;

    var objectToClean = obj;
    Object.keys(objectToClean).forEach(function (key) {
        if (objectToClean[key] && typeof objectToClean[key] === 'object') {
            cleanObject(objectToClean[key]);
        } else if (obj[key] == null) delete objectToClean[key];

        if (objectToClean && (!isNaN(objectToClean.quantity) && objectToClean.quantity !== '')) {
            objectToClean.quantity = Number(objectToClean.quantity);
        }
    });

    return obj;
}

/**
 * Retrieves analytics data from elements on the page
 * @param {*} selector - jQuery element or selector of the meta tag containing the analytics data
 * @returns {Object[]} Cleaned array of analytics data
 */
const fetchCommerceData = (selector) => {
    let commerceData = [];
    const selectr = selector || '.product[data-pid] meta[itemprom=analytics], .product-info meta[itemprom=analytics]';

    let listName = '';
    if ($('.product-set-detail').length) {
        listName = $('.product-set-detail').data('pid');
        sessionStorage.setItem('stl_source', listName);
        sessionStorage.setItem('stl_source_needs_removal', false);
    } else if (sessionStorage.getItem('stl_source')) {
        listName = sessionStorage.getItem('stl_source');
    }

    $(selectr).each(function (index, trackingItem) {
        const $trackingItem = $(trackingItem);
        const jsonString = $trackingItem.attr('content');

        if (jsonString && jsonString.length > 0) {
            let jsonObject = JSON.parse(jsonString);
            jsonObject.list_name = listName;
            commerceData.push(cleanObject(jsonObject));
        }
    });

    return commerceData;
};

/**
 * Returns the cookie if found
 * @param {*} cName - The cookie name id
 * @returns {string} founded cookie
 */
function getCookie(cName) {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res || 'false';
}

module.exports = {
    getBreadCrumbPath: getBreadCrumbPath,
    cleanObject: cleanObject,
    fetchCommerceData: fetchCommerceData,
    getCookie: getCookie
};
