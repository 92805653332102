'use strict';

const formValidation = require('../components/formValidation');
const Dialog = require('../util/dialog');

const PasswordReset = {};

PasswordReset.init = () => {
    PasswordReset.onPasswordResetSubmit();
    PasswordReset.onShowRequestForm();
    PasswordReset.onClearResetForm();
    PasswordReset.onEmailAllreadyExists();
    PasswordReset.closeOtherModals();
};

PasswordReset.closeOtherModals = () => {
    $('body').on('show.bs.modal', '#requestPasswordResetModal', function () {
        $('.modal.show').modal('hide');
    });
};

PasswordReset.toggleModalHeaderContent = () => {
    $('.request-password-title').toggleClass('d-none');
    $('.request-password-body').toggleClass('d-none');
    $('.send-email-btn').toggleClass('d-none');
};

PasswordReset.onPasswordResetSubmit = () => {
    $('.reset-password-form').on('submit', (event) => {
        event.preventDefault();
        const form = $(event.currentTarget);
        const url = form.attr('action');
        form.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: (data) => {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                    $('.reset-password-form').trigger('passwordreset:error', data);
                } else {
                    PasswordReset.toggleModalHeaderContent();
                    $('.reset-password-form').trigger('passwordreset:mail-sent');
                }
            },
            error: () => {
                form.spinner().stop();
            }
        });
        return false;
    });
};

PasswordReset.onShowRequestForm = () => {
    $(document).on('click', '.js-show-request-form', () => {
        PasswordReset.toggleModalHeaderContent();
    });
};

PasswordReset.onClearResetForm = () => {
    $('.modal.request-password-reset').on('hidden.bs.modal', () => {
        $('#reset-password-email').val('');
        $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
    });
};

PasswordReset.onEmailAllreadyExists = () => {
    $('button.reset-password').on('click', (e) => {
        e.preventDefault();
        var url = $('.reset-password-button').data('submit-url'); // Account-PasswordResetDialogForm
        var email = $('.reset-password-button').data('email');
        var dataMockobj = { loginEmail: email };
        $.post(url, dataMockobj).done(() => {
            Dialog.hide();
        });
    });
};

PasswordReset.onEIDPasswordReset = () => {
    var redirect;
    $('.reset-eid-password-form').on('submit', (event) => {
        event.preventDefault();
        const form = $(event.currentTarget);
        const url = form.attr('action');
        form.spinner().start();
        var parent = $('.reset-eid-password-form').parent();
        $('.invalid-feedback', parent).text('');
        // Endpoint: eid-changepassword
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: (data) => {
                if (data.succes === 'PASSWORD_DENIED') {
                    formValidation(form, data);
                } else if (data.succes === 'PASSWORD_ACCEPTED') {
                    parent.html(
                        `<p> ${data.message} </p>`
                    );
                    $('.header__title').text(data.title);
                    $('.close-popup').removeClass('d-none');
                    redirect = data.redirect;
                }
                form.spinner().stop();
            },
            error: () => {
                form.spinner().stop();
            }
        });
    });
    $('.close-popup').on('click', () => {
        $('.js-popup-close').trigger('click');
    });
    $('.js-popup-close').on('click', () => {
        if (redirect) window.location = redirect;
    });
};

module.exports = PasswordReset;
