'use strict';

module.exports = {
    init: () => {
        $('select.custom-select').each(function (index, customSelect) {
            var $customSelect = $(customSelect);
            var ariaLabel = $customSelect.attr('aria-label');

            $customSelect.selectric();

            $customSelect.parents('.selectric-wrapper').find('.selectric-input').attr('aria-label', ariaLabel);
        });
    }
};
