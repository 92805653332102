'use strict';

const isMobile = require('../util/isMobile');

module.exports = {
    /**
     * Initializes a slick slider.
     *
     * @param {string} className - The classname to initialize Slick on. (Element should contain the slides as direct children)
     * @param {int} slidesToShow - Amount of slides to show
     * @param {boolean} showArrows - Wether or not to show the navigation arrows
     * @param {int} slidesToScroll - Amount of slides to scroll
     * @param {string} asNavFor - Act as navigation for
     * @param {string} slickFor - The classname where the slick for is
     * @param {boolean} showDots - Show dots underneath slider if true
     * @param {boolean} variableWidth - uses variable width if true
     */
    initializeSlider: function (className, slidesToShow, showArrows, slidesToScroll, asNavFor, slickFor, showDots, variableWidth) {
        const $slider = $(className);
        if (!$slider.length) return;

        const $sliderContainer = $slider.parent('.slider');
        const $prevArrow = $slider.siblings('.prev-arrow');
        const $nextArrow = $slider.siblings('.next-arrow');
        const $slidesToShowContainer = $slider.parents('[data-slick-slidestoshow]');

        let forcedSlidesToShow = !isMobile.any() && $slidesToShowContainer.data('slick-slidestoshow');

        if (forcedSlidesToShow && $slider.parents('[data-slick-slidestoshow]').data('slick-for') !== slickFor) {
            forcedSlidesToShow = slidesToShow;
        }

        const config = {
            slidesToShow: forcedSlidesToShow || slidesToShow,
            slidesToScroll: slidesToScroll || 1,
            arrows: showArrows,
            prevArrow: $prevArrow,
            nextArrow: $nextArrow,
            infinite: false,
            touchMove: window.touchMove || true,
            dots: showDots,
            variableWidth: variableWidth
        };

        if (asNavFor) {
            config.asNavFor = asNavFor;
            config.focusOnSelect = true;
        }

        $slider.slick(config);

        if (showArrows) {
            setTimeout(function () {
                $prevArrow.removeClass('d-none');
                $nextArrow.removeClass('d-none');
            });
        }

        $sliderContainer.on('mousedown mouseup', function () {
            const parents = $(this).parents('.slick-slider');
            const currentSlide = parents.slick('slickCurrentSlide');
            parents.slick('slickGoTo', currentSlide);
        });

        const $sliderWrappingCurrentSlider = $sliderContainer.parents('.slick-slider');
        if ($sliderWrappingCurrentSlider.length) {
            $slider.on('mousedown touchstart', () => {
                $sliderWrappingCurrentSlider.slick('setOption', 'swipe', false);
            });
            $slider.on('mouseup touchend', () => {
                $sliderWrappingCurrentSlider.slick('setOption', 'swipe', true);
            });
        }
    }
};
