'use strict';

const ApplePay = {};

ApplePay.applePayContent = '.js-apple-pay-content';

ApplePay.init = () => {
    if (!ApplePay.isApplePaySupported()) {
        ApplePay.removeApplePaymentOption();
        return;
    }

    ApplePay.showApplePaymentOption();
};

// Check if browser supports Apple Pay
ApplePay.isApplePaySupported = () => {
    return window.ApplePaySession && window.ApplePaySession.canMakePayments();
};

ApplePay.showApplePaymentOption = () => {
    $(ApplePay.applePayContent).removeClass('d-none').addClass('d-flex');
};

ApplePay.removeApplePaymentOption = () => {
    $(ApplePay.applePayContent).remove();
};

module.exports = ApplePay;
