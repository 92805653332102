'use strict';

const select = require('../components/select');
const Recaptcha = require('../util/recaptcha');
let url;
let isDefault;

let addressBookObj = {};

addressBookObj.showFormPopUp = (formUrl, dataTitleSelector, modalSelector, doRefresh) => {
    const processInclude = require('base/util');
    const phoneNumbers = require('../util/phoneNumbers');

    $.spinner().start(true);
    $.ajax({
        url: formUrl, // Address-EditAddress || Address-AddAddress || Address-EditGuestAddress
        type: 'get',
        success: function (data) {
            var $modal = $(modalSelector);
            $modal.find('.modal-content').html(data);

            const $title = $modal.find('#editAddAddressContainer .js-addressbook-modal-title');
            const title = $title.data(dataTitleSelector);
            $title.text(title);

            phoneNumbers.init();

            // Handle address actions
            require('./addressForm').init(modalSelector, doRefresh);
            processInclude(require('../components/clientSideValidation'));
            require('../util/autocomplete').init();
            require('../util/customSelectric').init();

            select.init();
            $modal.modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

addressBookObj.setDefaultAddress = () => {
    $('input[type=radio][name=default-address]').on('change', function () {
        $.spinner().start(true);
        let setDefaultAddressurl = $(this).data('action');
        if (setDefaultAddressurl) {
            $.ajax({
                url: setDefaultAddressurl,
                type: 'get',
                success: function () {
                    window.location.reload();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
};

addressBookObj.removeAddress = () => {
    $('.remove-address').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        isDefault = $this.data('default');
        if (isDefault) {
            url = $this.data('url')
                + '&isDefault='
                + isDefault;
        } else {
            url = $this.data('url');
        }
        const $container = $('#deleteAddressModalContainer');
        const title = $container.data('dialog-title');
        const message = $container.data('dialog-message');
        const confirm = $container.data('dialog-confirm');
        const cancel = $container.data('dialog-cancel');
        var Dialog = require('../util/dialog');
        Dialog.show(Dialog.TYPE_CONFIRM, title, message, {
            accept: {
                text: confirm,
                callback: function () {
                    $.spinner().start();
                    Recaptcha.generateToken('account_removeaddress').then(function (token) {
                        $.ajax({
                            url: url + '&g-recaptcha-response=' + token,
                            type: 'get',
                            dataType: 'json',
                            success: function () {
                                if ($this.hasClass('noReload')) {
                                    if ($this.parents('.address_container').find('.custom-control-input').is(':checked')) {
                                        $('.js-default').parent().trigger('click');
                                    }
                                    $('[data-id=' + $this.data('id') + ']').parents('.address_container').remove();
                                    $(document).trigger('checkout:address:select', [e]);
                                } else {
                                    window.location.reload();
                                }
                                $.spinner().stop();
                            },
                            error: function (err) {
                                if (err.responseJSON.redirectUrl) {
                                    window.location.href = err.responseJSON.redirectUrl;
                                }

                                $.spinner().stop();
                            }
                        });
                    }).catch(function () {
                        $.spinner().stop();

                        const errMsg = $('.error-messaging').data('uncaught-error-msg');
                        const errTitle = $('.error-messaging').data('uncaught-error-title');
                        Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                            accept: {
                                text: 'OK'
                            }
                        });
                    });
                }
            },
            cancel: {
                text: cancel
            }
        }, null, $container.html());
    });
};

addressBookObj.addAddress = () => {
    $('.account-address-overview__button--add').on('click', function (e) {
        e.preventDefault();
        addressBookObj.showFormPopUp($(this).attr('href'), 'title-add', '#addressBookModal', true);
    });
};

addressBookObj.editAddress = () => {
    $('.card__edit-link').on('click', function (e) {
        e.preventDefault();
        addressBookObj.showFormPopUp($(this).attr('href'), 'title-edit', '#addressBookModal', true);
    });
};

addressBookObj.init = () => {
    require('../checkout/checkoutHelpers').initChangePhoneType();
};

module.exports = addressBookObj;
