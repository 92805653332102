'use strict';

/**
 * Switches icons on click / touch
 * @param {*} $el - Button that contains the icons
 */
function toggleIcons($el) {
    var icons = $el.find('.show-icon');
    if (icons.length) {
        icons.each(function () {
            $(this).toggleClass('d-none');
        });
    }
}

/**
 * Makes the passwordfield visible to the users.
 */
module.exports = {
    initViewPassword: function () {
        $('.form__button--show-password').each(function () {
            $(this).off(); // Remove previous event listeners
            let data = `[name=${$(this).data('for-input-name')}],${$(this).data('for-input-id')}`;
            $(this).on('mousedown touchstart', function () {
                $(this).siblings(data).attr('type', 'text');
                toggleIcons($(this));
            });
            $(this).on('mouseup touchend', function () {
                $(this).siblings(data).attr('type', 'password');
                toggleIcons($(this));
            });
        });
    }
};
