'use strict';

const PassPort = require('../account/passPort');
const select = require('../components/select');
const Datepicker = require('../util/datepicker');

const RavotclubHelper = {};

RavotclubHelper.showRavotterModal = (content) => {
    $('#ravotterModal .modal-content').html(content);
    require('../components/form').initViewPassword();
    $('#ravotterModal').modal('show');
};

RavotclubHelper.handleAddRavotclubMember = () => {
    PassPort.init();
    PassPort.submitPassPortForm();

    $('body').on('click', '.js-pdp-add-ravotter', function (e) {
        e.preventDefault();
        $.spinner().start(true);

        var url = $(this).data('url'); // Account-AddPassPort

        $.ajax({
            url: url,
            type: 'get',
            data: {
                pdp: true
            },
            success: function (data) {
                $('#ravotterModal .modal-content').html(data);

                Datepicker.init('#ravotterModal #birthday');
                require('../util/customSelectric').init();
                select.init();

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('change', '#ravotterModal input#ravotclub', function () {
        var checked = $(this).is(':checked');
        $('.submit-passport-form').prop('disabled', !checked);
    });
};

module.exports = RavotclubHelper;
