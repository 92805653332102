'use strict';

const Dialog = require('../util/dialog');
const emailCheckHelper = require('../util/emailCheckHelper');

const CheckoutHelpers = {};

CheckoutHelpers.showErrorDialog = (message, customTitle) => {
    const $checkout = $('.checkout');
    const title = customTitle || $checkout.data('error-dialog-title');
    const acceptButtonText = $checkout.data('error-dialog-confirm');
    Dialog.show(Dialog.TYPE_ERROR, title, message, {
        accept: {
            text: acceptButtonText
        }
    }, null, null);
};

/**
 * Adds a handler to the email form field that shows a dialog
 * to inform the customer about a existing account.
 */
CheckoutHelpers.addEmailExistsHandler = () => {
    $('#registration-form-email').on('blur', function () {
        const email = $('#registration-form-email').val();
        const url = $('.checkout .email-checker').data('check-email-exists-url'); // Account-EmailExists
        const title = $('.checkout .email-checker').data('check-email-exists-pop-up-title');

        if (email && email !== '') {
            emailCheckHelper.check(url, email).then(function (data) {
                if (data.exists) {
                    $('#registration-form-email').addClass('is-invalid');
                    const checkoutLoginScript = require('../checkout/checkoutLogin');
                    const formScript = require('../components/form');
                    const clientSideValidation = require('../components/clientSideValidation');

                    Dialog.show(Dialog.TYPE_CONFIRM, title, '', {}, '#emailExistsModalContainer', null, [checkoutLoginScript, formScript, clientSideValidation], true);
                }
            });
        }
    });
};

CheckoutHelpers.initChangePhoneType = () => {
    $('body').on('change', '.js-phone-type-select', function () {
        const $phone = $('.js-phone-container');
        const $mobile = $('.js-mobile-container');

        $phone.toggleClass('d-none');
        $mobile.toggleClass('d-none');
    });
};

module.exports = CheckoutHelpers;
