'use strict';

const SUPPORTED_COUNTRIES = ['BE'];
const DATA_AUTOCOMPLETE_CITY = 'data-autocomplete-city';
const DATA_AUTOCOMPLETE_POSTCODE = 'data-autocomplete-postcode';

const { default: PerfectScrollbar } = require('perfect-scrollbar');
const formHelpers = require('./formHelpers');

const Autocomplete = {};

Autocomplete.init = () => {
    const $countrySelect = $('[data-field="countrycode"]');
    $countrySelect.each(function () {
        Autocomplete.determinePostalCodeInputMaxLength($(this));
        const $container = $(this).closest('.js-address-container');
        if (SUPPORTED_COUNTRIES.indexOf(Autocomplete.getSelectedCountryCode($container)) >= 0) {
            Autocomplete.initCityAutocomplete($container);
            Autocomplete.initPostalCodeAutocomplete($container);
        }
    });
    Autocomplete.initAddressAutocomplete();
    Autocomplete.initAutocompleteOnCountrySelect();
};

Autocomplete.determinePostalCodeInputMaxLength = ($country) => {
    const length = formHelpers.determineLengthPostalCodeInput($country.val());
    $country.closest('.js-address-container').find('[data-field="postalCode"]').attr('maxlength', length.maxlength);
    $country.closest('.js-address-container').find('[data-field="postalCode"]').attr('minlength', length.minlength);
};

Autocomplete.initAutocompleteOnCountrySelect = () => {
    $('[data-field="countrycode"]').on('change', (e) => {
        const $this = $(e.currentTarget);
        const $parentContainer = $this.closest('.js-address-container');
        const $postalCode = $parentContainer.find('[data-field="postalCode"]');
        Autocomplete.determinePostalCodeInputMaxLength($this);
        Autocomplete.disposeAutocomplete($parentContainer.find(`[${DATA_AUTOCOMPLETE_CITY}]`));
        Autocomplete.disposeAutocomplete($parentContainer.find(`[${DATA_AUTOCOMPLETE_POSTCODE}]`));
        Autocomplete.enableCityAndAddress1Fields($parentContainer);

        const country = Autocomplete.getSelectedCountryCode($parentContainer);
        if (Autocomplete.isNL(country)) {
            $postalCode.trigger('change');
        }
        if (SUPPORTED_COUNTRIES.indexOf(country) < 0) return;

        Autocomplete.initCityAutocomplete($parentContainer);
        Autocomplete.initPostalCodeAutocomplete($parentContainer);
    });
};

Autocomplete.getSelectedCountryCode = ($container) => {
    return $container.find('[data-field="countrycode"]').val();
};

Autocomplete.isNL = (countryCode) => {
    return countryCode && countryCode === 'NL';
};

Autocomplete.disposeAutocomplete = ($element) => {
    if ($element.length) {
        $element.autocomplete('dispose');
    }
};

Autocomplete.initAutocomplete = ($element, transformResult, onSelect, $container) => {
    const country = Autocomplete.getSelectedCountryCode($container);
    const url = $element.data('url');
    let ps;
    if ($element.length > 0) {
        $element.autocomplete({
            serviceUrl: url + '?countryCode=' + country,
            transformResult: transformResult,
            onSelect: onSelect,
            beforeRender: (container) => {
                if (ps) {
                    ps.update();
                } else {
                    ps = new PerfectScrollbar(container[0], { suppressScrollX: true });
                }
            },
            onHide: () => {
                if (ps) {
                    ps.destroy();
                    ps = null;
                }
            }
        });
        $element.autocomplete().enable();
    }
};

Autocomplete.initCityAutocomplete = ($container) => {
    const transformResult = (response) => {
        return {
            suggestions: $.map(JSON.parse(response).suggestions, function (dataItem) {
                return { value: dataItem.value + ', ' + dataItem.data, data: dataItem.data };
            })
        };
    };
    const onSelect = function (suggestion) {
        setTimeout(() => {
            $(this)
                .val(suggestion.value.split(',')[0]);
            $(this)
                .parent()
                .parent()
                .find(`[${DATA_AUTOCOMPLETE_POSTCODE}]`)
                .val(suggestion.data);
            $container.find(`[${DATA_AUTOCOMPLETE_POSTCODE}]`).autocomplete().disable();
            $container.find(`[${DATA_AUTOCOMPLETE_POSTCODE}]`).trigger('change');
            setTimeout(function () {
                $container.find(`[${DATA_AUTOCOMPLETE_POSTCODE}]`).trigger('blur');
                $container.find(`[${DATA_AUTOCOMPLETE_POSTCODE}]`).autocomplete().enable();
            }, 0);
        }, 200);
    };
    Autocomplete.initAutocomplete($container.find(`[${DATA_AUTOCOMPLETE_CITY}]`), transformResult, onSelect, $container);
};

Autocomplete.initPostalCodeAutocomplete = ($container) => {
    const transformResult = (response) => {
        return {
            suggestions: $.map(JSON.parse(response).suggestions, function (dataItem) {
                return { value: dataItem.data + ', ' + dataItem.value, code: dataItem.value, city: dataItem.data };
            })
        };
    };
    const onSelect = function (suggestion) {
        setTimeout(() => {
            $(this).val(suggestion.city);
            $(this).parent()
                .parent()
                .find('[data-field="postalCode"]')
                .val(suggestion.code);
            $container.find(`[${DATA_AUTOCOMPLETE_CITY}]`).autocomplete().disable();
            $container.find(`[${DATA_AUTOCOMPLETE_CITY}]`).trigger('change');
            setTimeout(function () {
                $container.find(`[${DATA_AUTOCOMPLETE_CITY}]`).trigger('blur');
                $container.find(`[${DATA_AUTOCOMPLETE_CITY}]`).autocomplete().enable();
            }, 0);
        }, 200);
    };
    Autocomplete.initAutocomplete($container.find(`[${DATA_AUTOCOMPLETE_POSTCODE}]`), transformResult, onSelect, $container);
};

Autocomplete.enableCityAndAddress1Fields = ($container) => {
    $container.find('[data-field="city"]').removeAttr('readonly');
    $container.find('[data-field="address1"]').removeAttr('readonly');
};

Autocomplete.initAddressAutocomplete = () => {
    $('[data-field="postalCode"], [data-field="addressNumber"]').on('change blur', function () {
        const $this = $(this);
        const $container = $this.closest('.js-address-container');
        const country = Autocomplete.getSelectedCountryCode($container);

        if (!Autocomplete.isNL(country)) return;

        const postalCode = $container.find('[data-field="postalCode"]').val();
        const addressNumber = $container.find('[data-field="addressNumber"]').val();
        const dataUrl = $this.parents('form').data('url');

        if (postalCode && postalCode.length > 0 && addressNumber && addressNumber.length > 0) {
            $.get(`${dataUrl}?postalCode=${postalCode}&addressNumber=${addressNumber}`, function (data) {
                if (data.suggestions && data.suggestions.length > 0) {
                    $container.find('[data-field="address1"]').val(data.suggestions[0].streetName);
                    $container.find('[data-field="address1"]').attr('readonly', 'readonly');
                    $container.find('[data-field="city"]').val(data.suggestions[0].city);
                    $container.find('[data-field="city"]').attr('readonly', 'readonly');
                } else {
                    Autocomplete.enableCityAndAddress1Fields($container);
                }
            });
        }
    });
};

module.exports = Autocomplete;
