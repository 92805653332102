'use strict';

var Recaptcha = require('../util/recaptcha');
const Dialog = require('../util/dialog');
const appendToUrl = require('../util/appendToUrl');

module.exports = {
    /**
     * Generates a recaptcha token and invokes the check email exists endpoint
     * @param {string} url The url of the endpoint that needs to be invoked
     * @param {string} email The email address that needs to be checked
     * @returns {JQueryDeferred<any>} jQuery deffered object
     */
    check: function (url, email) {
        return Recaptcha.generateToken('check_email_exists').then(function (token) {
            let urlWithParams = url;

            urlWithParams = appendToUrl(urlWithParams, {
                email: email,
                'g-recaptcha-response': token
            });

            var def = jQuery.Deferred();
            $.ajax({
                url: urlWithParams,
                type: 'GET',
                success: function (data) {
                    return def.resolve(data);
                },
                error: function (data) {
                    return def.reject(data);
                }
            });
            return def.promise();
        }).catch(function () {
            $.spinner().stop();

            const errMsg = $('.error-messaging').data('uncaught-error-msg');
            const errTitle = $('.error-messaging').data('uncaught-error-title');
            Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                accept: {
                    text: 'OK'
                }
            });
        });
    }
};
