'use strict';

const emailCheckHelper = require('../util/emailCheckHelper');
const formValidation = require('../components/formValidation');
var Recaptcha = require('../util/recaptcha');
const confirmationPopUp = require('../util/confirmationPopUp');
const error = $('#confirmationPopUp').data('error');
const passPort = require('./passPort');
const Dialog = require('../util/dialog');
const Datepicker = require('../util/datepicker');

/**
 * Adds a listener to the email formfield to check if the email address already exists
 */
function addCheckEmailExistsHandler() {
    $('form.registration').on('blur', '#registration-form-email', function () {
        const $form = $('form.registration');
        let url = $form.data('check-email-exists-url'); // Account-EmailExists
        const email = $('#registration-form-email').val();
        const title = $form.data('check-email-exists-pop-up-title');

        $('#registration-form-email').removeClass('is-invalid');

        if (email && email !== '') {
            emailCheckHelper.check(url, email).then(function (data) {
                if (data.exists) {
                    $('#registration-form-email').addClass('is-invalid');
                    const registerLoginScript = require('../account/registerLogin');
                    const formScript = require('../components/form');
                    const clientSideValidation = require('../components/clientSideValidation');

                    Dialog.show(Dialog.TYPE_CONFIRM, title, '', {}, '#emailExistsModalContainer', null, [registerLoginScript, formScript, clientSideValidation], true);
                    $('body').trigger('register:email-exists');
                }
            });
        }
    });
}

const replaceName = ($input, count) => {
    $input.prop('name', `${$input.prop('name')}_${count}`);
};

const addChildFields = (cardNr, childNr) => {
    var url = $('.registration__ravotclub-content').data('url');

    $.ajax({
        url: url,
        type: 'get',
        data: {
            cardNumber: cardNr,
            childNumber: childNr
        },
        success: function (data) {
            $('.registration__ravotclub-content').removeClass('d-none');
            $('.registration__ravotclub-content .registration__ravotclub-passports').append(data);
            Datepicker.init(`.passport-container[data-item-count="${childNr}"] .reg-passport-form .js-input-bday-ravotter`);
            require('../util/customSelectric').init();

            $('.registration__ravotclub-passports .passport-container').last().find('input, select, textarea').each(function () {
                replaceName($(this), childNr);
            });
        }
    });
};

/**
 * This will listen for account type changes and it will show or hide the company name and vat fields.
 */
const addAccountTypeChangeListener = () => {
    const ACCOUNT_TYPE_PERSONAL = 0;
    const ACCOUNT_TYPE_BUSINESS = 1;

    let $container = $('.js-typeform');
    let $companynameContainer = $('#companynameContainer');
    let $btwContainer = $('#btwContainer');

    $('input[type=radio][name=accounttype]').on('change', function () {
        if (+this.value === ACCOUNT_TYPE_PERSONAL) {
            $container.hide();
            $companynameContainer.find('#registration-form-company-name').removeAttr('required');
            $btwContainer.find('#registration-form-btw').removeAttr('required');
        } else if (+this.value === ACCOUNT_TYPE_BUSINESS) {
            $container.show();
            $container.addClass('checkout-anonymous-border');
            $companynameContainer.find('#registration-form-company-name').attr('required', 'required');
            $btwContainer.find('#registration-form-btw').attr('required', 'required');
        }
        $companynameContainer.toggleClass('required');
        $btwContainer.toggleClass('required');
    });
};

module.exports = {
    init: function () {
        require('../checkout/checkoutHelpers').initChangePhoneType();
        require('../util/customSelectric').init();

        passPort.init();
        Datepicker.init('#birthday');

        addAccountTypeChangeListener();
        addCheckEmailExistsHandler();

        $('body').on('change', '.js-input-bday-ravotter', function () {
            var maximumAge = 18;
            var dates = $(this).val().split('/');
            var year = dates[2];

            var today = new Date();
            var currentYear = today.getFullYear();

            var $err = $(this).siblings('.invalid-feedback');
            var $btn = $(this).parents('.reg-passport-form').find('.submit-passport-form');

            if (parseInt(year, 10) < (parseInt(currentYear, 10) - maximumAge)) {
                $err.html($(this).data('age-limit'));
                $err.addClass('d-block');
                $btn.prop('disabled', true);
            } else {
                $err.addClass('d-none');
                $err.html('');
                $btn.removeAttr('disabled');
            }
        });
    },
    submitProfile: function () {
        $('form.registration').on('submit', function (e) {
            let $form = $(this);
            let gender = $("form.registration .gender input[type='radio']:checked").val();
            $('form.registration #hiddengender').val(gender);
            let accounttype = $("form.registration .account-type input[type='radio']:checked").val();
            $('form.registration #hiddenaccounttype').val(accounttype);

            const phoneNumbers = require('../util/phoneNumbers');
            $('#mobilephone').val(phoneNumbers.getPhoneNumberFromInput('#mobilephone'));
            $('#phone').val(phoneNumbers.getPhoneNumberFromInput('#phone'));

            e.preventDefault();
            let url = $form.attr('action');
            $form.spinner().start();
            $('form.registration').trigger('profile:edit', e);
            Recaptcha.generateToken('account_register').then(function (token) {
                var requestData = $form.serialize();
                requestData += '&g-recaptcha-response=' + token;

                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: requestData,
                    success: function (data) {
                        $form.spinner().stop();
                        if (!data.success) {
                            formValidation($form, data);
                            if (data.popupTemplate) {
                                var template = data.popupTemplate;
                                $(document).trigger('idTouchPoint:fire', { email_sha256: template.email_sha256, sscid: '', touchpointName: 'registration' });
                                Dialog.show(Dialog.TYPE_CONFIRM, template.title, null, null, null, template.html, [require('../login/passwordReset').onEmailAllreadyExists], false);
                            }
                        } else {
                            location.href = data.redirectUrl;
                        }
                    },
                    error: function () {
                        confirmationPopUp.show(error, confirmationPopUp.TYPE_ERROR);
                        $form.spinner().stop();
                    }
                });
            }).catch(function () {
                $.spinner().stop();

                const errMsg = $('.error-messaging').data('uncaught-error-msg');
                const errTitle = $('.error-messaging').data('uncaught-error-title');
                Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                    accept: {
                        text: 'OK'
                    }
                });
            });

            return false;
        });
    },

    addPassPortRavotter: function () {
        $('body').on('change', '.ravotclub__checkbox input', function () {
            $.spinner().start(true);

            var $contentContainer = $('.registration__ravotclub-content');

            if ($(this).is(':checked')) {
                // check if there are already children
                // possible if they unchecked it and recheck it. but in this case there shouldnt be a new form.
                var passportAmount = $contentContainer.find('.registration__ravotclub-passports .passport-container').length;
                if (passportAmount && passportAmount > 0) {
                    $contentContainer.removeClass('d-none');
                } else {
                    addChildFields(1, 1);
                }
            } else {
                $contentContainer.addClass('d-none');

                $('button.cancel-passport-form').trigger('click', true);
            }

            $.spinner().stop();
        });
    },

    submitPassPortRegistration: function () {
        $('body').on('click', '.registration__ravotclub-content .submit-passport-form', function (e) {
            e.preventDefault();

            const $container = $(this).closest('.passport-container');
            const $fieldSet = $container.find('fieldset.reg-passport-form');

            // check if required fields are empty
            let valid = true;
            $fieldSet.find('input, select, textarea').each(function () {
                if (!this.validity.valid) {
                    $(this).trigger('invalid', this.validity);

                    valid = false;
                }
            });

            if (valid) {
                // fetch fields
                const firstName = $fieldSet.find('.js-input-fname-ravotter').val();
                const lastName = $fieldSet.find('.js-input-lname-ravotter').val();
                const birthDate = $fieldSet.find('.js-input-bday-ravotter').val();
                const shoeSize = $fieldSet.find('.js-input-shoesize-ravotter').val();
                let gender = $fieldSet.find('.js-ravotter-gender-select').val();

                const insole = $fieldSet.find('.js-input-insole-ravotter:checked').val();

                // fill in correct values for hidden fields
                var relation = parseInt(gender, 10) ? 'DAUGHTER' : 'SON';
                $fieldSet.find('.js-input-relation-ravotter').val(relation);

                $('.js-input-hiddengender-ravotter').val(gender);
                $('.js-input-hiddeninsole-ravotter').val(insole);

                // hide this form
                $fieldSet.addClass('d-none');

                // add values to passport card
                $container.find('.reg-passport-card .familyMember-name').text(firstName + ' ' + lastName);
                $container.find('.reg-passport-card .passport-shoeSize').text(shoeSize);
                const $insoleDisplay = $container.find('.reg-passport-card .passport-insole');
                $insoleDisplay.text(parseInt(insole, 10) ? $insoleDisplay.data('yes') : $insoleDisplay.data('no'));
                const $genderDisplay = $container.find('.reg-passport-card .passport-gender');
                $genderDisplay.text(parseInt(gender, 10) ? $genderDisplay.data('girl') : $genderDisplay.data('boy'));
                $container.find('.reg-passport-card .passport-bday').text(birthDate);

                // show ravotter card
                $container.find('.reg-passport-card').removeClass('d-none');
                // show button 'add new ravotter'
                $('.add-reg-passport').removeClass('d-none');

                $container.find('.reg-passport-form .cancel-passport-form').removeClass('js-edit');
            }
        });
    },

    addNewRavotterRegistrationForm: function () {
        $('body').on('click', '.add-reg-passport', function (e) {
            e.preventDefault();
            $.spinner().start(true);

            let count = $('.registration__ravotclub-passports .passport-container').last().data('item-count') + 1;
            let cardStyling = (count % 4) + 1;

            addChildFields(cardStyling, count);

            // remove 'add new button'
            $('.add-reg-passport').addClass('d-none');
            $.spinner().stop();
        });
    },
    editRavotterPassport: function () {
        $('body').on('click', 'button.card__edit-link-ravotter', function (e) {
            e.preventDefault();
            const $container = $(this).closest('.passport-container');
            $container.find('.reg-passport-card').addClass('d-none');
            $container.find('.reg-passport-form').removeClass('d-none');
            $container.find('.reg-passport-form .cancel-passport-form').addClass('js-edit');
            $('.add-reg-passport').addClass('d-none');
        });
    },
    removeRavotterPassport: function () {
        $('body').on('click', 'button.remove-from-passport', function (e) {
            e.preventDefault();
            var passportAmount = $('.registration__ravotclub-passports .passport-container').length;
            if (passportAmount <= 1) {
                $('.ravotclub__checkbox input[type="checkbox"]').prop('checked', false);
                $('.ravotclub__checkbox input').trigger('change');
                $('.add-reg-passport').addClass('d-none');
            }
            const $container = $(this).closest('.passport-container');
            $container.remove();
        });
    },
    cancelRavotterPassport: function () {
        $('body').on('click', 'button.cancel-passport-form', function (e, fromTrigger) {
            e.preventDefault();
            const $container = $(this).closest('.passport-container');

            if ($(this).hasClass('js-edit')) {
                $container.find('.reg-passport-card').removeClass('d-none');
                $container.find('.reg-passport-form').addClass('d-none');
                $('.add-reg-passport').removeClass('d-none');
            } else {
                var passportAmount = $('.registration__ravotclub-passports .passport-container').length;
                if (passportAmount <= 1) {
                    if (!fromTrigger) {
                        $('.ravotclub__checkbox input[type="checkbox"]').prop('checked', false);
                        $('.ravotclub__checkbox input').trigger('change');
                    }
                } else {
                    $('.add-reg-passport').removeClass('d-none');
                }

                $container.remove();
            }
        });
    }
};
