'use strict';

const Dialog = require('../util/dialog');

/**
 * Helps build the html to show in the dialog
 * @param {Array} notfoundFields - array of not found field attributes
 * @param {string} parentSelector - selector of the parent
 * @param {Array} fieldErrors - the fields with errors
 * @returns {string} - html with errors to display
 */
function createNotFoundFieldsErrorsHtml(notfoundFields, parentSelector, fieldErrors) {
    let notFoundErrorHtml = '<ul class="pl-0">';
    for (let i = 0; i < notfoundFields.length; i++) {
        const notfoundField = notfoundFields[i];
        const $notFoundInput = $('*[name=' + notfoundField + ']', parentSelector);
        let label = $notFoundInput[0].ariaLabel ? $notFoundInput[0].ariaLabel + ' ' : '';
        notFoundErrorHtml += '<li>';
        if ($notFoundInput.length && label.length < 1) {
            label = $notFoundInput.parent().find('label').text().replace(' *', '');
        }
        notFoundErrorHtml += `${label}: `;
        notFoundErrorHtml += `"${fieldErrors[notfoundField]}"</li>`;
    }
    notFoundErrorHtml += '</ul>';
    return notFoundErrorHtml;
}

/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 */
function loadFormErrors(parentSelector, fieldErrors) {
    // Display error messages and highlight form fields with errors.
    var notfoundFields = [];
    const shippingNotFoundFields = [];
    const billingNotFoundFields = [];
    $.each(fieldErrors, function (attr) {
        var $errorElement = $('*[name=' + attr + ']', parentSelector);
        if ($errorElement.length > 0) {
            $errorElement.addClass('is-invalid')
                .siblings('.invalid-feedback')
                .html(fieldErrors[attr]);

            if (!$errorElement.is(':visible')) {
                notfoundFields.push(attr);
                if (attr.toLowerCase().includes('billing')) {
                    billingNotFoundFields.push(attr);
                } else if (attr.toLowerCase().includes('shipping')) {
                    shippingNotFoundFields.push(attr);
                }
            }
        } else {
            notfoundFields.push(attr);
            if (attr.toLowerCase().includes('billing')) {
                billingNotFoundFields.push(attr);
            } else if (attr.toLowerCase().includes('shipping')) {
                shippingNotFoundFields.push(attr);
            }
        }
    });
    const $invalidInput = $('.is-invalid').first();

    if ($invalidInput && $invalidInput.length > 0) {
        const offset = $('.header').height() + 50;
        $('html, body').animate({
            scrollTop: $invalidInput.offset().top - offset
        }, 500, 'linear');
    }

    if (notfoundFields.length > 0) {
        const $parent = $(parentSelector);
        const mainErrorTitle = $parent.data('main-error-title');
        const mainErrorMessage = $parent.data('main-error-message');
        const mainErrorOk = $parent.data('main-error-ok');
        const mainErrorShippingaddress = $parent.data('main-error-shippingaddress');
        const mainErrorBillingaddress = $parent.data('main-error-billingaddress');

        if (mainErrorTitle && mainErrorMessage && mainErrorOk) {
            let notFoundErrorHtml = '';
            if (shippingNotFoundFields.length > 0) {
                notFoundErrorHtml += `<p class="mt-3"><strong>${mainErrorShippingaddress}</strong></p>`;
                notFoundErrorHtml += createNotFoundFieldsErrorsHtml(shippingNotFoundFields, parentSelector, fieldErrors);
            }
            if (billingNotFoundFields.length > 0) {
                notFoundErrorHtml += `<p class="mt-3"><strong>${mainErrorBillingaddress}</strong></p>`;
                notFoundErrorHtml += createNotFoundFieldsErrorsHtml(billingNotFoundFields, parentSelector, fieldErrors);
            }
            Dialog.show(Dialog.TYPE_ERROR, mainErrorTitle, mainErrorMessage, {
                accept: {
                    text: mainErrorOk
                }
            }, null, notFoundErrorHtml);
        }
    }
}

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
function clearPreviousErrors(parentSelector) {
    $(parentSelector).find('.form-control.is-invalid').removeClass('is-invalid');
    $('.error-message').hide();
}

module.exports = {
    loadFormErrors: loadFormErrors,
    clearPreviousErrors: clearPreviousErrors
};
