'use strict';

const FormHelpers = {};

FormHelpers.determineLengthPostalCodeInput = (countryCode) => {
    let minlength;
    let maxlength;
    switch (countryCode) {
        case 'BE':
            maxlength = 4;
            minlength = maxlength;
            break;
        case 'NL':
            maxlength = 6;
            minlength = maxlength;
            break;
        default:
            maxlength = 9;
            minlength = 4;
    }
    return { maxlength: maxlength, minlength: minlength };
};

module.exports = FormHelpers;
