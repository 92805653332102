'use strict';

const getHours = (timeString) => {
    if (timeString) {
        const [hours, minutes] = timeString.split(':');

        let openDate = new Date();
        openDate.setHours(hours, minutes);

        return openDate;
    }

    return false;
};

const calculateCurrentlyOpen = () => {
    const $container = $('.js-customerservice-hours');
    if (!$container || !$container.length) return;

    const locale = $('#location-holder').data('locale-language');
    const customerServiceHours = $container.data('hours');
    const exceptionalClosingDays = $container.data('closing-days');
    if (!locale || !customerServiceHours) return;

    const hourObject = customerServiceHours[locale];
    const currentDate = new Date();

    const days = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
    ];

    let isExceptionallyClosedToday = false;
    if (exceptionalClosingDays) {
        const exceptionalClosingDaysArray = exceptionalClosingDays[locale];
        if (exceptionalClosingDaysArray && exceptionalClosingDaysArray.length) {
            const currentDayFormatted = `${currentDate.getDate()}/${currentDate.getMonth() + 1}`;
            isExceptionallyClosedToday = exceptionalClosingDaysArray.includes(currentDayFormatted);
        }
    }

    const day = days[currentDate.getDay()];

    if (!hourObject[day]) return;

    const openTime = hourObject[day].open;
    const closeTime = hourObject[day].close;

    const openHours = getHours(openTime);
    const closedHours = getHours(closeTime);

    if (!isExceptionallyClosedToday && ((openTime && openTime !== 'closed') && (closeTime && closeTime !== 'closed')) && ((openHours && openHours <= currentDate) && (closedHours && closedHours > currentDate))) {
        $('.js-customerservice-phone').removeClass('d-none');
        $('.js-customerservice-message').addClass('d-none');
    } else {
        $('.js-customerservice-phone').addClass('d-none');
        $('.js-customerservice-message').removeClass('d-none');
    }
};

const init = () => {
    calculateCurrentlyOpen();
};

module.exports = {
    init: init
};
