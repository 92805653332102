'use strict';

const init = () => {
    const $selects = $('.form-group select');
    $selects.each(function () {
        const label = $(this).attr('aria-label');
        let selectricOptions = {
            labelBuilder: `<span>${label}</span><span>{text}</span>`
        };

        $(this).selectric(selectricOptions);
    });
};

module.exports = {
    init: init
};
