'use strict';

/* globals dataLayer */

const processInclude = require('base/util');

const addressHelpers = require('base/checkout/address');
const formErrors = require('base/checkout/formErrors');
/**
 * updates the shipping address selector within shipping forms
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} customer - the customer model
 */
function updateShippingAddressSelector(productLineItem, shipping, order, customer) {
    var uuidEl = $('input[value=' + productLineItem.UUID + ']');
    var shippings = order.shipping;

    var form;
    var $shippingAddressSelector;
    var hasSelectedAddress = false;

    if (uuidEl && uuidEl.length > 0) {
        form = uuidEl[0].form;
        $shippingAddressSelector = $('.addressSelector', form);
    }

    if ($shippingAddressSelector && $shippingAddressSelector.length === 1) {
        $shippingAddressSelector.empty();
        // Add New Address option
        $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            null,
            false,
            order
        ));
        if (customer.addresses && customer.addresses.length > 0) {
            $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(order.resources.accountAddresses, false, order));
            customer.addresses.forEach(function (address) {
                var isSelected = shipping.matchingAddressId === address.ID;
                $shippingAddressSelector.append(
                    addressHelpers.methods.optionValueForAddress({
                        UUID: 'ab_' + address.ID,
                        shippingAddress: address
                    }, isSelected, order)
                );
            });
        }
        // Separator -
        $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(order.resources.shippingAddresses, false, order, { className: 'multi-shipping' }));
        shippings.forEach(function (aShipping) {
            var isSelected = shipping.UUID === aShipping.UUID;
            hasSelectedAddress = hasSelectedAddress || isSelected;
            var addressOption = addressHelpers.methods.optionValueForAddress(
                aShipping,
                isSelected,
                order,
                { className: 'multi-shipping' }
            );

            var newAddress = addressOption.html() === order.resources.addNewAddress;
            var matchingUUID = aShipping.UUID === shipping.UUID;
            if ((newAddress && matchingUUID) || (!newAddress && matchingUUID) || (!newAddress && !matchingUUID)) {
                $shippingAddressSelector.append(addressOption);
            }
            if (newAddress && !matchingUUID) {
                $(addressOption[0]).remove();
            }
        });
    }

    if (!hasSelectedAddress) {
        // show
        $(form).addClass('hide-details');
    } else {
        $(form).removeClass('hide-details');
    }
}

/**
 * updates the shipping address form values within shipping forms
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateShippingAddressFormValues(shipping) {
    var addressObject = Object.assign({}, shipping.shippingAddress);

    if (!addressObject) {
        addressObject = {
            firstName: null,
            lastName: null,
            address1: null,
            address2: null,
            city: null,
            postalCode: null,
            stateCode: null,
            countryCode: null,
            phone: null
        };
    }

    $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
        var form = el.form;
        if (!form) return;
        var countryCode = addressObject.countryCode;

        $('input[name$=_firstName]', form).val(addressObject.firstName);
        $('input[name$=_lastName]', form).val(addressObject.lastName);
        $('input[name$=_address1]', form).val(addressObject.address1);
        $('input[name$=_address2]', form).val(addressObject.address2);
        $('input[name$=_city]', form).val(addressObject.city);
        $('input[name$=_postalCode]', form).val(addressObject.postalCode);
        $('select[name$=_stateCode],input[name$=_stateCode]', form)
            .val(addressObject.stateCode);

        if (countryCode && typeof countryCode === 'object') {
            $('select[name$=_country]', form).val(addressObject.countryCode.value);
        } else {
            $('select[name$=_country]', form).val(addressObject.countryCode);
        }

        $('input[name$=_phone]', form).val(addressObject.phone);
    });
}

/**
 * updates the order shipping summary for an order shipment model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 */
function updateShippingSummaryInformation(shipping, order) {
    $('[data-shipment-summary=' + shipping.UUID + ']').each(function (i, el) {
        var $container = $(el);
        var $shippingAddressLabel = $container.find('.shipping-addr-label');
        var $addressContainer = $container.find('.address-summary');
        var $shippingPhone = $container.find('.shipping-phone');
        var $methodTitle = $container.find('.shipping-method-title');
        var $methodArrivalTime = $container.find('.shipping-method-arrival-time');
        var $methodPrice = $container.find('.shipping-method-price');
        var $shippingSummaryLabel = $container.find('.shipping-method-label');
        var $summaryDetails = $container.find('.row.summary-details');

        var address = shipping.shippingAddress;
        var selectedShippingMethod = shipping.selectedShippingMethod;

        addressHelpers.methods.populateAddressSummary($addressContainer, address);

        if (address && address.phone) {
            $shippingPhone.text(address.phone);
        } else {
            $shippingPhone.empty();
        }

        if (selectedShippingMethod) {
            $('body').trigger(
                'shipping:updateAddressLabelText',
                { selectedShippingMethod: selectedShippingMethod, resources: order.resources, shippingAddressLabel: $shippingAddressLabel }
            );
            $shippingSummaryLabel.show();
            $summaryDetails.show();
            $methodTitle.text(selectedShippingMethod.displayName);
            if (selectedShippingMethod.estimatedArrivalTime) {
                $methodArrivalTime.text(
                    '( ' + selectedShippingMethod.estimatedArrivalTime + ' )'
                );
            } else {
                $methodArrivalTime.empty();
            }
            $methodPrice.text(selectedShippingMethod.shippingCost);
        }
    });
}

/**
 * Update the read-only portion of the shipment display (per PLI)
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} [options] - options for updating PLI summary info
 * @param {Object} [options.keepOpen] - if true, prevent changing PLI view mode to 'view'
 */
function updatePLIShippingSummaryInformation(productLineItem, shipping, order, options) {
    var keepOpen = options && options.keepOpen;

    var $pli = $('input[value=' + productLineItem.UUID + ']');
    var form = $pli && $pli.length > 0 ? $pli[0].form : null;

    if (!form) return;

    var $viewBlock = $('.view-address-block', form);

    var hasAddress = !!shipping.shippingAddress;
    var address = shipping.shippingAddress || {};
    var selectedMethod = shipping.selectedShippingMethod;

    var nameLine = address.firstName ? address.firstName + ' ' : '';
    if (address.lastName) nameLine += address.lastName;

    var address1Line = address.address1;
    var address2Line = address.address2;

    var phoneLine = address.phone;

    var shippingCost = selectedMethod ? selectedMethod.shippingCost : '';
    var methodNameLine = selectedMethod ? selectedMethod.displayName : '';
    var methodArrivalTime = selectedMethod && selectedMethod.estimatedArrivalTime
        ? '(' + selectedMethod.estimatedArrivalTime + ')'
        : '';

    var tmpl = $('#pli-shipping-summary-template').clone();

    $('.ship-to-name', tmpl).text(nameLine);
    $('.ship-to-address1', tmpl).text(address1Line);
    $('.ship-to-address2', tmpl).text(address2Line);
    $('.ship-to-city', tmpl).text(address.city);
    if (address.stateCode) {
        $('.ship-to-st', tmpl).text(address.stateCode);
    }
    $('.ship-to-zip', tmpl).text(address.postalCode);
    $('.ship-to-phone', tmpl).text(phoneLine);

    if (!address2Line) {
        $('.ship-to-address2', tmpl).hide();
    }

    if (!phoneLine) {
        $('.ship-to-phone', tmpl).hide();
    }

    if (shipping.selectedShippingMethod) {
        $('.display-name', tmpl).text(`${methodNameLine} (${shippingCost})`);
        $('.estimated-arrival-time', tmpl).text(methodArrivalTime);
    }
    // checking h5 title shipping to or pickup
    var $shippingAddressLabel = $('.shipping-header-text', tmpl);
    $('body').trigger(
        'shipping:updateAddressLabelText',
        { selectedShippingMethod: selectedMethod, resources: order.resources, shippingAddressLabel: $shippingAddressLabel }
    );

    $viewBlock.html(tmpl.html());

    if (!keepOpen) {
        if (hasAddress) {
            $viewBlock.parents('[data-view-mode]').attr('data-view-mode', 'view');
        } else {
            $viewBlock.parents('[data-view-mode]').attr('data-view-mode', 'enter');
        }
    }
}

/**
 * Update the hidden form values that associate shipping info with product line items
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateProductLineItemShipmentUUIDs(productLineItem, shipping) {
    $('input[value=' + productLineItem.UUID + ']').each(function (key, pli) {
        var form = pli.form;
        $('[name=shipmentUUID]', form).val(shipping.UUID);
        $('[name=originalShipmentUUID]', form).val(shipping.UUID);
    });
}

/**
 * Update the shipping UI for a single shipping info (shipment model)
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order/basket model
 * @param {Object} customer - the customer model
 * @param {Object} [options] - options for updating PLI summary info
 * @param {Object} [options.keepOpen] - if true, prevent changing PLI view mode to 'view'
 */
function updateShippingInformation(shipping, order, customer, options) {
    // First copy over shipmentUUIDs from response, to each PLI form
    order.shipping.forEach(function (aShipping) {
        aShipping.productLineItems.items.forEach(function (productLineItem) {
            updateProductLineItemShipmentUUIDs(productLineItem, aShipping);
        });
    });

    // Now update shipping information, based on those associations
    // updateShippingMethods(shipping);
    updateShippingAddressFormValues(shipping);
    updateShippingSummaryInformation(shipping, order);

    // And update the PLI-based summary information as well
    shipping.productLineItems.items.forEach(function (productLineItem) {
        updateShippingAddressSelector(productLineItem, shipping, order, customer);
        updatePLIShippingSummaryInformation(productLineItem, shipping, order, options);
    });
}

/**
 * Handle response from the server for valid or invalid form fields.
 * @param {Object} defer - the deferred object which will resolve on success or reject.
 * @param {Object} data - the response data with the invalid form fields or valid model data.
 * @returns {Object} defer - the deferred object which will resolve on success or reject.
 */
function shippingFormResponse(defer, data) {
    // highlight fields with errors
    if (data.error) {
        if (data.fieldErrors.length) {
            data.fieldErrors.forEach(function (error) {
                if (Object.keys(error).length) {
                    formErrors.loadFormErrors('.single-shipping form', error);
                }
            });

            return defer.reject(data);
        }

        if (data.cartError) {
            window.location.href = data.redirectUrl;
            return defer.reject();
        }
    } else {
        var $selectedShippingMethod = $('[name="dwfrm_shipping_shippingAddress_shippingMethodID"]:checked');
        var $shippingDates = $selectedShippingMethod.parent().find('.js-shippingDate');
        if ($shippingDates.length > 0) {
            if (!$shippingDates.hasClass('selected')) {
                $selectedShippingMethod.parent().find('.error-shipping-date').removeClass('d-none');

                return defer.reject();
            }
        }

        // Upgrade Datalayer to GDDL
        if (data.hashedEmail && dataLayer) {
            var touchpointName;
            if (data.registeredCheckout) {
                touchpointName = '';
            } else {
                touchpointName = 'guestCheckout';
            }
            var dataObject = {
                email_sha256: data.hashedEmail,
                sscid: '',
                touchpointName: touchpointName
            };
            $(document).trigger('idTouchPoint:fire', dataObject);
        }

        // Populate the Address Summary
        $('body').trigger('checkout:updateCheckoutView', { order: data.order, customer: data.customer });
    }

    /*
        Updates Payment options
        placed in comments since everything has been moved to a onepager
        and billing is submitted at the same time as this function will be executed
    */
    // require('./billing').updatePaymentOptions(data);

    return defer;
}

/**
 * Clear out all the shipping form values and select the new address in the drop down
 * @param {Object} order - the order object
 */
function clearShippingForms(order) {
    order.shipping.forEach(function (shipping) {
        $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
            var form = el.form;
            if (!form) return;

            $('input[name$=_firstName]', form).val(null);
            $('input[name$=_lastName]', form).val(null);
            $('input[name$=_address1]', form).val(null);
            $('input[name$=_address2]', form).val(null);
            $('input[name$=_city]', form).val(null);
            $('input[name$=_postalCode]', form).val(null);
            $('select[name$=_stateCode],input[name$=_stateCode]', form).val(null);
            $('select[name$=_country]', form).val(null);

            $('input[name$=_phone]', form).val(null);

            $(form).attr('data-address-mode', 'new');
            var addressSelectorDropDown = $('.addressSelector option[value=new]', form);
            $(addressSelectorDropDown).prop('selected', true);
        });
    });
}

/**
 * Does Ajax call to create a server-side shipment w/ pliUUID & URL
 * @param {string} url - string representation of endpoint URL
 * @param {Object} shipmentData - product line item UUID
 * @returns {Object} - promise value for async call
 */
function createNewShipment(url, shipmentData) {
    $.spinner().start();
    return $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: shipmentData
    });
}

const selectFirstDayShipping = () => {
    const $selected = $('.shipping-method-template').find('.checkout-content-card.selected');
    if (!$selected || !$selected.length) {
        const $firstMethod = $('.shipping-method-template .js-method-container:not(.delivery-moment-evening):not([data-id="SAT"])').first();
        $firstMethod.trigger('click');
    }
};

const selectMainShippingMethod = () => {
    $('body').on('change', '.checkout-shipping-methods-main .shippingMethod', function () {
        var $shippingMethod = $(this);

        var methodID = $shippingMethod.find(':checked').attr('data-shipping-id');
        var url = $shippingMethod.parent().attr('data-url'); // Checkout-SelectShippingMethod
        var urlParams = {
            methodID: methodID
        };

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            data: urlParams,
            success: function (data) {
                // Update Address cards
                $('.js-checkout-addresses').html(data.addressesHtml);
                $('.shipping-method-list').html(data.deliveryMethods.template);
                $('body').trigger('checkout:updateCheckoutView', { order: data.order });

                const checkoutObj = require('./checkout');
                checkoutObj.initSliders();
                checkoutObj.initDeliveryOptionSliders();
                selectFirstDayShipping();

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

const changeStoreToDeliver = () => {
    $('body').on('click', '.change-fav-store', function (e) {
        e.preventDefault();
        $.spinner().start(true);

        const url = $(this).attr('href'); // Stores-StoreSelector

        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                if (!data.includes('<!DOCTYPE html>')) {
                    var $modal = $('#checkoutModal');
                    $modal.find('.modal-content').html(data);
                    processInclude(require('../storeSelector/storeSelector')); // Move to top of function?
                    $modal.modal('show');
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

const changePickupPoint = () => {
    processInclude(require('../pickUpPointSelector/pickUpPointSelector'));

    $('body').on('click', '.change-collection-point', function (e) {
        e.preventDefault();

        const url = $(this).attr('href'); // Stores-PickUpPointSelector

        $.spinner().start(true);
        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                if (!data.toString().includes('<!DOCTYPE html>')) {
                    var $modal = $('#checkoutModal');
                    $modal.find('.modal-content').html(data);
                    $modal.modal('show');
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

const shippingDateTimeListener = () => {
    $(document).on('click keyup', '.js-method-container', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (e.type !== 'click' && e.key !== 'Enter') return;

        const $this = $(this);
        const isEveningDelivery = $this.hasClass('delivery-moment-evening');
        const isSelected = $this.parent().hasClass('selected');
        const selectedMethodId = $this.parents('.shipping-method-template').find('[data-selected-method-id]').attr('data-selected-method-id');

        if (!isSelected || isEveningDelivery || (isSelected && selectedMethodId === 'EVN')) {
            const $selectedElement = isSelected && isEveningDelivery ? $this.parents('.shipping-method-template').find('.js-method-container').first() : $this;
            const isSelectedEvening = $selectedElement.hasClass('delivery-moment-evening');

            // Unselect all the elements
            const $contentCards = $('.shipping-method-template .checkout-content-card');
            $contentCards.removeClass('selected');
            $contentCards.removeAttr('data-selected-method-id');
            $('.delivery-moment-shippingcost.invisible').removeClass('invisible');
            $('input[type=checkbox][id^="evt-"]').attr('checked', false);

            // Add Selected styling
            const $parent = isSelectedEvening ? $selectedElement.parents('.shipping-method-template').find('.checkout-content-card') : $selectedElement.parent();
            $parent.addClass('selected');
            $parent.first().attr('data-selected-method-id', $selectedElement.data('id'));

            if (isSelectedEvening) {
                $parent.find('.delivery-moment-shippingcost').addClass('invisible');
            }

            var $cb = $selectedElement.find('input[type=checkbox][id^="evt-"]');
            if ($cb) {
                $cb.attr('checked', true);
            }

            const url = $('.shipping-method-list').data('update-url'); // CheckoutShippingServices-UpdateDeliveryTime
            $.ajax({
                url: url,
                type: 'POST',
                data: {
                    id: $selectedElement.data('id'),
                    date: $selectedElement.data('date')
                },
                success: function (data) {
                    $('body').trigger('checkout:updateCheckoutView', { order: data.order }); // update checkout view
                }
            });
        }
    });
};

module.exports = {
    selectMainShippingMethod: selectMainShippingMethod,
    changeStoreToDeliver: changeStoreToDeliver,
    changePickupPoint: changePickupPoint,
    methods: {
        updateShippingAddressSelector: updateShippingAddressSelector,
        updateShippingAddressFormValues: updateShippingAddressFormValues,
        updateShippingSummaryInformation: updateShippingSummaryInformation,
        updatePLIShippingSummaryInformation: updatePLIShippingSummaryInformation,
        updateProductLineItemShipmentUUIDs: updateProductLineItemShipmentUUIDs,
        updateShippingInformation: updateShippingInformation,
        shippingFormResponse: shippingFormResponse,
        createNewShipment: createNewShipment,
        clearShippingForms: clearShippingForms
    },
    shippingDateTimeListener: shippingDateTimeListener,
    selectFirstDayShipping: selectFirstDayShipping
};
