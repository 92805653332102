'use strict';

const intlTelInput = {};

const preferredCountries = ['be', 'nl'];
$('body').on('reevaluatePhoneNumber', function () {
    $('.iti input').trigger('blur');
});

intlTelInput.setup = (inputType) => {
    let $input = $(inputType.id);
    var input = $input[0];

    if (!input || window.intlTelInputGlobals.getInstance(input)) return;
    var iti = window.intlTelInput(input, {
        initialCountry: $('#location-holder').data('locale-country-code'), // for ip based start 'auto',
        // geoIpLookup: (callback) =>{
        //     $.get('https://ipinfo.io', function () {}, 'jsonp').always(function (resp) {
        //         var countryCode = (resp && resp.country) ? resp.country : '';
        //         callback(countryCode);
        //     });
        // },
        placeholderNumberType: inputType.type,
        preferredCountries: preferredCountries,
        utilsScript: require('../thirdParty/phoneUtils')
    });

    if ($input.val() !== '') {
        $input.parent().parent().find('label')
            .addClass('focussed');
    }

    $input.change(() => {
        if ($input.val() !== '') {
            $input.parent().parent().find('label')
                .addClass('focussed');
        }
    });

    $input.on('focus', function () {
        var $label = $(this).parent().parent().find('label');
        $label.addClass('focussed');
    });

    $input.on('blur', function () {
        var $error = $(this).parent().parent().find('.invalid-feedback');
        $error.text('');

        if ($(this).val() === '') {
            var $label = $(this).parent().parent().find('label');
            $label.removeClass('focussed');
        }

        $(this).removeClass('is-invalid');
        if (input.value.trim()) {
            if (!iti.isValidNumber()) {
                $(this).trigger('invalid', this.validity);
                if ($(this).val() < 1 && $(this).attr('required')) {
                    $error.css('display', 'block');
                    $error.text($(this).data('missing-error'));
                    this.setCustomValidity($(this).data('missing-error'));
                } else {
                    $error.css('display', 'block');
                    $error.text($(this).data('pattern-mismatch'));
                    this.setCustomValidity($(this).data('pattern-mismatch'));
                }
                $(this).addClass('is-invalid');
            } else { this.setCustomValidity(''); }
        }
    });

    $input.on('keydown', (function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
        return true;
    }));
};

intlTelInput.init = () => {
    if (!window.intlTelInput) {
        window.intlTelInput = require('../thirdParty/intlTelInput');
    }
    const phoneInputs = [
        { id: '#phone', type: 'FIXED_LINE' },
        { id: '#mobilephone', type: 'MOBILE' },
        { id: '#editAddAddressContainer #phone', type: 'FIXED_LINE' },
        { id: '#editAddAddressContainer #mobilephone', type: 'MOBILE' },
        { id: '#shipping-address-form-phone', type: 'FIXED_LINE' },
        { id: '#shipping-address-form-mobilephone', type: 'MOBILE' }
    ];
    phoneInputs.forEach(input => {
        intlTelInput.setup(input);
    });
};

intlTelInput.getPhoneNumberFromInput = (id) => {
    let input = $(id);

    if (input.length) {
        return window.intlTelInputGlobals.getInstance(input[0]).getNumber();
    }

    return null;
};

module.exports = intlTelInput;
