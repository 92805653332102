'use strict';

const appendToUrl = require('../util/appendToUrl');
const { default: PerfectScrollbar } = require('perfect-scrollbar');

const setSelectedCollectionPoint = () => {
    $('body').on('click', '.pick-up-point-results .results .btn-primary', function () {
        let pickUpPoint = $(this).data('pick-up-point'); // Object - Address
        if (pickUpPoint) {
            const $resultContainer = $('.results');
            const postService = $resultContainer.data('post-service'); // Bpost || PostNL

            let url = $resultContainer.data('url'); // Cart-SetSelectedCollectionPoint
            url = appendToUrl(url, {
                postService: postService
            });

            $.ajax({
                url: url,
                type: 'post',
                data: JSON.stringify({
                    selectedCollectionPoint: pickUpPoint
                }),
                contentType: 'application/json',
                success: function (data) {
                    if (data.success) {
                        // Update shipping address display
                        $('.js-collection-point-address').html(data.pickupPointAddress);
                        $('.js-collection-point-address').addClass('shipping-address-information checkout-content-card mr-3');

                        // Hide modal
                        var $modal = $('#checkoutModal');
                        $modal.modal('hide');
                    }
                }
            });
        }
    });
};

/**
 * Renders the results of the search and updates the map
 * @param {string} countryCode - the country code (BE, NL, ...)
 * @param {string} postalCode - the postal code
 */
function updatePickUpPointsResults(countryCode, postalCode) {
    let ps = null;
    const methodId = $('.shippingMethods').find(':checked').attr('data-shipping-id');

    const $contentContainer = $('.pick-up-point-results');
    let url = $contentContainer.data('url'); // Stores-PickUpPoints
    url = appendToUrl(url, {
        countryCode: countryCode,
        postalCode: postalCode,
        methodId: methodId
    });

    $.ajax({
        url: url, // Stores-PickUpPoints
        type: 'get',
        success: function (pupData) {
            if (pupData.errorMsg) {
                let $err = $('.pick-up-point-selector-form').find('.invalid-feedback');
                $err.text(pupData.errorMsg);
                $err.show();
            } else {
                $contentContainer.html(pupData.content);

                const hasResults = $('.results').data('has-results');
                if (!hasResults) {
                    $('.store-locator__no-results').show();
                    if (ps) {
                        ps.destroy();
                        ps = null;
                    }
                } else {
                    $('.store-locator__no-results').hide();
                    $('#storeLocatorSearchInput').val('');
                    ps = new PerfectScrollbar($('.pick-up-point-results')[0], { suppressScrollX: true });
                }

                setSelectedCollectionPoint();
            }

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Detects location and shows results
 */
function detectLocation() {
    $.spinner().start();

    const kioskStoreId = localStorage.getItem('kioskStoreId');
    const $locBtn = $('.detect-location');
    if (kioskStoreId && $locBtn && $locBtn.length) {
        let url = $('.detect-location').data('get-store-by-id-url');
        url = appendToUrl(url, {
            storeId: kioskStoreId
        });

        $.ajax({
            url: url,
            type: 'get',
            success: function (data) {
                var countryCode = $('#location-holder').data('locale-country-code');
                updatePickUpPointsResults(countryCode, data.store.postalCode);
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    } else {
        if (!navigator.geolocation) {
            $.spinner().stop();
            return;
        }

        navigator.geolocation.getCurrentPosition(function (position) {
            var googleMapsUrl = $('#storeLocatorSearchInput').data('googlemapsurl');
            var googleMapsUrlParams = {
                latlng: `${position.coords.latitude},${position.coords.longitude}`
            };
            googleMapsUrl = appendToUrl(googleMapsUrl, googleMapsUrlParams);

            $.ajax({
                url: googleMapsUrl,
                type: 'get',
                success: function (data) {
                    if (data.status === 'OK') {
                        var countryCode = $('#location-holder').data('locale-country-code');
                        var postalCode = data.results && data.results[0] && data.results[0].address_components && data.results[0].address_components[data.results[0].address_components.length - 1] ? data.results[0].address_components[data.results[0].address_components.length - 1].long_name : null;
                        updatePickUpPointsResults(countryCode, postalCode);
                    } else {
                        $.spinner().stop();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }, function () {
            $.spinner().stop();
        });
    }
}

module.exports = {
    detectLocation: function () {
        // clicking on detect location.
        $('body').on('click', 'form.pick-up-point-selector-form .detect-location', function (e) {
            e.preventDefault();
            detectLocation();
        });
    },

    search: function () {
        $('body').on('submit', 'form.pick-up-point-selector-form', function (e) {
            e.preventDefault();
            $.spinner().start();

            let $form = $(this);
            let $input = $form.find('[name="postalCode"]');
            let $err = $form.find('.invalid-feedback');
            $err.hide();

            let countryCode;
            const postalCode = $input.val();
            const methodID = $('.shippingMethods').find(':checked').attr('data-shipping-id');

            switch (methodID) {
                case 'AFHBP':
                    countryCode = 'BE';
                    break;
                case 'AFHPNL':
                    countryCode = 'NL';
                    break;
                default:
                    countryCode = $('#location-holder').data('country-code');
                    break;
            }

            updatePickUpPointsResults(countryCode, postalCode);
        });
    }
};
