'use strict';

const processInclude = require('base/util');

$(() => {
    require('./checkout/applePay').init();
    require('./checkout/checkout').init();
    processInclude(require('./checkout/qrcode'));
    require('./account/register').init();
    require('./util/autocomplete').init();
    require('./checkout/billing').init();
    require('./checkout/ravotclubOptin').init();
    require('./header/customerservice').init();
    require('./analytics/checkoutAnalytics').init();
});

require('./thirdParty/autocomplete');
require('./thirdParty/magnific');
