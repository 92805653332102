'use strict';

let addressFormObj = {};

addressFormObj.cancel = (modalSelector) => {
    $('#cancel-address-form-button').on('click', function (e) {
        e.preventDefault();

        // Close popup
        var $modal = $(modalSelector);
        $modal.modal('hide');
    });
};

addressFormObj.submitAddress = (modalSelector, doRefresh) => {
    const phoneNumbers = require('../util/phoneNumbers');
    const formValidation = require('../components/formValidation');

    $('form.address-form').on('submit', function (e) {
        e.preventDefault();

        $(this).trigger('reevaluatePhoneNumber');
        const $form = $(this);
        if ($form[0].checkValidity()) {
            $('form.address-form #mobilephone').val(phoneNumbers.getPhoneNumberFromInput('form.address-form #mobilephone'));
            $('form.address-form #phone').val(phoneNumbers.getPhoneNumberFromInput('form.address-form #phone'));

            $.spinner().start();
            $.ajax({
                url: $form.attr('action'), // Address-SaveAddress
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation($form, data);
                    } else {
                        var $modal = $(modalSelector);
                        $modal.modal('hide');

                        if (doRefresh) {
                            location.reload();
                        }
                    }

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });
        }

        return false;
    });
};

addressFormObj.showTooltip = () => {
    $('#addressId').tooltip();
};

addressFormObj.init = (modalSelector, doRefresh) => {
    const address = require('../util/address');

    $(() => {
        address.changePostalCodeInputTypeOnMobile($('#country'), $('#postalCode'));

        $('#country').on('change', function () {
            address.changePostalCodeInputTypeOnMobile($('#country'), $('#postalCode'));
        });
    });

    addressFormObj.cancel(modalSelector);
    addressFormObj.submitAddress(modalSelector, doRefresh);
    addressFormObj.showTooltip();
};

module.exports = addressFormObj;
