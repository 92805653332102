'use strict';

const isMobile = require('./isMobile');

module.exports = {
    changePostalCodeInputTypeOnMobile: function (countryElement, postalCodeElement) {
        if (!isMobile.mobileOrTablet()) {
            return;
        }

        if (countryElement.val() === 'BE') {
            postalCodeElement.attr('type', 'number');
            postalCodeElement.attr('inputmode', 'numeric');
        } else {
            postalCodeElement.attr('type', 'text');
            postalCodeElement.removeAttr('inputmode');
        }
    }
};
