'use strict';

const GDDLHelper = require('./helpers/helper');

var atrack;

const initAnalyticsTracker = () => {
// Initialize the GDDL analytics tracker
    atrack = window.analyticstracker();
    atrack.setHTMLSelectors({
        eventSelector: 'data-event',
        infoSelector: 'data-analytics',
        extendPageInfo: ['url', 'userAgent', 'platform', 'domain', 'referrer']
    });
};

const determinePaymentMethod = () => {
    let paymentMethod = '';

    const $selectedMethod = $('.billingmethod-container .payment-option-container.selected');
    if ($selectedMethod.length) {
        paymentMethod = $selectedMethod.data('method-id');
    }

    return paymentMethod;
};

const fetchResultObj = (printEmail, noPayment, noCheckoutType) => {
    let obj = {};
    const trackingJson = $('.js-checkout-tracking').attr('content');
    if (trackingJson && trackingJson.length > 0) {
        obj = JSON.parse(trackingJson);

        if (noPayment) {
            obj.delivery_method = '';
            obj.estimated_delivery_date = '';
            obj.payment_method = '';
        } else if (!obj.payment_method || !obj.payment_method.length) {
            obj.payment_method = determinePaymentMethod();
        }

        if (noCheckoutType) {
            obj.checkout_type = '';
        }

        if (!printEmail && obj.email !== '') {
            obj.email = '';
        }
    }

    return obj;
};

const initCheckoutBegin = () => {
    const $page = $('.page');
    if ($page.length > 0) {
        const action = $page.data('action');
        if (action === 'Checkout-Begin') { // || action === 'Checkout-BeginGuest'
            const eventData = {
                event: 'tool-step',
                info: {
                    type: 'form',
                    name: 'checkout',
                    placement: 'checkout',
                    step_number: '1',
                    step_name: 'personal data',
                    result: fetchResultObj(false, true),
                    error_type: '',
                    error_message: ''
                },
                commerce: GDDLHelper.fetchCommerceData()
            };

            eventData.info.result.transaction_id = '';
            atrack.trackEvent(eventData);
        }
    }
};

const initCheckoutConfirm = () => {
    const $page = $('.page');
    if ($page.length > 0) {
        const action = $page.data('action');
        if (action === 'Order-Confirm') {
            const $analyticsContainer = $('.js-analytics-container');
            let analyticsVar = $analyticsContainer.attr('data-analyticsvar');
            let orderNr = $analyticsContainer.attr('data-ordernr');

            if (
                analyticsVar && analyticsVar !== 'null'
                && orderNr && orderNr !== 'null'
                && analyticsVar === orderNr
            ) {
                const eventData = {
                    event: 'tool-laststep',
                    info: {
                        type: 'form',
                        name: 'checkout',
                        placement: 'checkout',
                        step_number: '2',
                        step_name: 'order confirmation',
                        result: fetchResultObj(true),
                        error_type: '',
                        error_message: ''
                    },
                    commerce: GDDLHelper.fetchCommerceData()
                };

                atrack.trackEvent(eventData);
                $('.js-analytics-container').attr('data-analyticsvar', null);
            }
        }
    }
};

const checkoutToolErrorListener = () => {
    $('body').on('checkout:toolError', function (e, errData) {
        const eventData = {
            event: 'tool-error',
            info: {
                type: 'form',
                name: 'checkout',
                placement: 'checkout',
                step_number: '1',
                step_name: 'personal data',
                result: fetchResultObj(),
                error_type: errData.type || '',
                error_message: errData.msg || ''
            },
            commerce: GDDLHelper.fetchCommerceData()
        };

        eventData.info.result.transaction_id = '';
        atrack.trackEvent(eventData);
    });
};

const initCheckoutbeginFromCart = () => {
    $('body').on('checkout:startFromCart', function () {
        const eventData = {
            event: 'tool-start',
            info: {
                type: 'form',
                name: 'checkout',
                placement: 'checkout',
                step_number: '',
                step_name: '',
                result: fetchResultObj(false, true, true),
                error_type: '',
                error_message: ''
            },
            commerce: GDDLHelper.fetchCommerceData('.js-cart-product-grid .product[data-pid] meta[itemprom=analytics], .js-cart-product-grid .product-info meta[itemprom=analytics]')
        };

        eventData.info.result.transaction_id = '';
        atrack.trackEvent(eventData);
    });
};

const initCheckoutEnd = () => {
    $('body').on('checkout:completeCustomCheckout', function () {
        const eventData = {
            event: 'tool-stepcomplete',
            info: {
                type: 'form',
                name: 'checkout',
                placement: 'checkout',
                step_number: '1',
                step_name: 'personal data',
                result: fetchResultObj(),
                error_type: '',
                error_message: ''
            },
            commerce: GDDLHelper.fetchCommerceData()
        };

        eventData.info.result.transaction_id = '';
        atrack.trackEvent(eventData);
    });
};

const init = () => {
    initAnalyticsTracker();
    initCheckoutBegin();
    initCheckoutEnd();
    initCheckoutConfirm();
    checkoutToolErrorListener();
};

const initCart = () => {
    initAnalyticsTracker();
    initCheckoutbeginFromCart();
};

module.exports = {
    init: init,
    initCart: initCart
};
