'use strict';

require('../thirdParty/datepicker');

const isMobile = require('../util/isMobile');

const Datepicker = {};

// Only init thirdparty datepicker on desktop. Use native datepicker on mobile devices.
Datepicker.init = (dateInputSelector) => {
    const $dateInput = $(dateInputSelector);

    if (!isMobile.any()) {
        $dateInput.datepicker({
            dateFormat: 'dd/mm/yy',
            onSelect: function () {
                // Trigger the blur event on the original input field so the validate on blur code gets triggered as well.
                $(this).trigger('blur');
            }
        });
        return;
    }

    // mobile setup to use native picker
    $dateInput.each((i, input) => {
        const currentValue = input.value;
        const $input = $(input);
        const $inputClone = $input.clone();
        $inputClone.attr('id', `date-input-clone-${i}`);
        $inputClone.attr('name', `date-input-clone-${i}`);
        $inputClone[0].type = 'date';

        // format date display from dd/mm/yyyy to yyyy-mm-dd for date input field if field has value
        if (currentValue && currentValue.length) {
            const dates = currentValue.split('/');
            const newValue = dates[2] + '-' + dates[1] + '-' + dates[0];
            $inputClone.val(newValue);
        }

        $inputClone.on('change', () => {
            const selectedDate = $inputClone.val();
            const splittedDate = selectedDate.split('-');
            const formattedDate = `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`;
            $input.val(formattedDate);
        });

        $input.hide().after($inputClone);
    });
};

module.exports = Datepicker;
