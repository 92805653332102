'use strict';

const RavotclubHelper = require('../util/ravotclubHelper');

const RavotclubOptin = {};

RavotclubOptin.init = () => {
    if (!$('.js-ravotclub-checkout-optin').length) return;

    RavotclubHelper.handleAddRavotclubMember();
    RavotclubOptin.onCheckboxToggle();
};

RavotclubOptin.onCheckboxToggle = () => {
    $('.js-ravotclub-checkout-optin input').click((e) => {
        e.preventDefault();
        e.stopPropagation();
    });

    $('.js-ravotclub-checkout-optin').click((e) => {
        $.spinner().start(true);
        const $this = $(e.currentTarget);
        const $checkbox = $this.find('input');
        const url = $this.data('url'); // endpoint: Checkout-RavotclubOptinSubmit
        $.post(url)
            .done((result) => {
                if (result.showRavotclubModal) {
                    $.spinner().stop();
                    RavotclubHelper.showRavotterModal(result.modalHtml);
                } else {
                    $checkbox.prop('checked', result.optIn);
                    location.reload();
                }
            })
            .fail(() => {
                $.spinner().stop();
            });
    });
};

module.exports = RavotclubOptin;
