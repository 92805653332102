'use strict';

module.exports = {
    TYPE_ERROR: 0,
    TYPE_SUCCESS: 1,
    show: function (confirmationObject, type, reload) {
        let $confirmationDialogModal = $('#confirmationPopUp');

        if (confirmationObject) {
            let $confirmButton = $confirmationDialogModal.find('.modal-footer .btn-primary');
            let $message = $confirmationDialogModal.find('.modal-body p');
            let $title = $confirmationDialogModal.find('.modal-title');

            $title.removeClass('title--error').removeClass('title--confirm');

            if (type === this.TYPE_SUCCESS) {
                $title.addClass('title--confirm');
            }

            if (type === this.TYPE_ERROR) {
                $title.addClass('title--error');
                if (reload) {
                    $confirmationDialogModal.on('hidden.bs.modal', function () {
                        location.reload();
                    });
                }
            }

            if (confirmationObject.title && confirmationObject.title.length > 0) {
                $title.text(confirmationObject.title);
            }

            if (confirmationObject.message && confirmationObject.message.length > 0) {
                $message.text(confirmationObject.message);
            }

            $confirmButton.find('.btn__inner').text(confirmationObject.confirmButtonText ? confirmationObject.confirmButtonText : 'OK');

            $confirmButton.on('click', function () {
                $confirmationDialogModal.modal('hide');
            });

            $confirmationDialogModal.modal('show');
        }
    }
};
