'use strict';

const isMobile = require('../util/isMobile');
const checkoutObj = require('../checkout/checkout');
const appendToUrl = require('../util/appendToUrl');
const { default: PerfectScrollbar } = require('perfect-scrollbar');

/**
 * Sets selectedStoreId of basket
 * @param {string} selectedStoreId - id of the selected store
 */
function setSelectedStore(selectedStoreId) {
    let url = $('.store-pick-up-container').data('set-selected-store-id-url');
    let setSelectedStoreUrlParams = {
        selectedStoreId: selectedStoreId,
        newlyChosenId: true
    };
    url = appendToUrl(url, setSelectedStoreUrlParams);
    $.ajax({
        url: url, // Checkout-SetSelectedStoreId
        type: 'post',
        success: function (data) {
        // Update store address cards

            // Remove previously selected card class
            const $currentSelectedStore = $('.js-select-store-address.selected');
            if ($currentSelectedStore.length) {
                $currentSelectedStore.removeClass('selected');
            }

            let $storeAddrCard = $('.js-torfs-store-address');
            if ($storeAddrCard && $storeAddrCard.length) {
                $storeAddrCard.html(data.pickupPointAddress);
            } else {
                const storeSlider = $('.js-address-slider-container');
                storeSlider.slick('slickAdd', `<div><div><div class="js-torfs-store-address js-select-store-address collection-point-address">${data.pickupPointAddress}<div></div></div>`); // first div is used by slick slided, second div is needed in the layout, third div is the address
                $storeAddrCard = $('.js-torfs-store-address');
            }

            $storeAddrCard.addClass('shipping-address-information checkout-content-card selected');
            $storeAddrCard.attr('data-store-id', selectedStoreId);

            // Scroll to slide
            const $sliders = $('.js-address-slider-container');
            checkoutObj.slidersScrollTo($sliders, isMobile.mobileOrTablet());
        }
    });
}

const updatePreferredStore = (favStoreId) => {
    const $favStoreCard = $('.js-saved-fav-store');
    let url = $favStoreCard.parents('.js-address-slider-container').data('update-fav-store-card');
    let setSelectedStoreUrlParams = {
        favStoreId: favStoreId,
        hasCardActive: $favStoreCard.hasClass('selected')
    };

    url = appendToUrl(url, setSelectedStoreUrlParams);
    $.ajax({
        url: url, // Checkout-updateFavoriteStoreCard
        type: 'get',
        success: function (data) {
            $favStoreCard.html(data.favStoreCardHtml);
        }
    });
};

/**
 * Gets the favorite store and inserts the favorite store html
 */
function getFavoriteStore() {
    const $container = $('.js-storeselector-favorite');
    const url = $container.data('get-fav-store-id'); // Stores-GetFavoriteStore
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (res) {
            if (res.success) {
                $container.html(res.storeHtml);
                $container.find(`#${res.store.ID}`).addClass('fav-store');
                $('.pup-favorite').addClass('d-none');
                $container.find('.pup-favorite').removeClass('d-none');
                $container.find('.js-storeselector-set-favorite').addClass('d-none');
            }
        }
    });
}

/**
 * Sets the preferred store
 * @param {string} preferredStoreId - the ID of the preferred store
 */
function setPreferredStore(preferredStoreId) {
    let url = $('.store-pick-up-container').data('set-preferred-store-url');
    let urlParams = {
        favStoreId: preferredStoreId
    };
    url = appendToUrl(url, urlParams);
    $.ajax({
        url: url, // Account-SetPreferredStore
        type: 'post',
        success: function () {
            getFavoriteStore();
        }
    });
}

const listenSelectStore = () => {
    // Remove previous listener if already there
    $('body').off('click', '.store-pick-up-results-container .btn-primary:not(.disabled)');

    // Listen to Select store button
    $('body').on('click', '.store-pick-up-results-container .btn-primary:not(.disabled)', function () {
        // fetch all store id's from cards
        let storeIdArray = [];
        const $storeCards = $('.js-select-store-address');
        $storeCards.each(function (index, storeCard) {
            storeIdArray.push($(storeCard).data('store-id'));
        });

        const selectedStoreId = $(this).data('store-id');

        if (storeIdArray.indexOf(selectedStoreId) !== -1) {
            const $storeCardToSelect = $(`.js-select-store-address[data-store-id='${selectedStoreId}']:not(.selected)`);
            $storeCardToSelect.trigger('click');
        } else {
            setSelectedStore(selectedStoreId);
        }

        // close popup
        var $modal = $('#checkoutModal');
        $modal.modal('hide');
    });
};

const listenSelectFavorite = () => {
    $('.results input[type="radio"]').on('change', function () {
        let favoriteStoreId = $(this).parents('.js-store-card-container')[0].id;
        if (favoriteStoreId) {
            setPreferredStore(favoriteStoreId);
            updatePreferredStore(favoriteStoreId);
        }
    });
};

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    let ps = null;
    const $resultsDiv = $('.results');
    const hasResults = data.stores.length > 0;

    // Update data results
    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    // Update Store results
    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);

        listenSelectStore();
        listenSelectFavorite();
    }

    if (!hasResults) {
        $('.store-locator__no-results').show();

        if (ps) {
            ps.destroy();
            ps = null;
        }
    } else {
        $('.store-locator__no-results').hide();
        $('#storeLocatorSearchInput').val('');

        ps = new PerfectScrollbar($('.store-pick-up-results-container')[0], { suppressScrollX: true });
    }
}

const ajaxStoreResults = (url, urlParams) => {
    const newUrl = appendToUrl(url, urlParams);
    $.ajax({
        url: newUrl,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            updateStoresResults(data);
        },
        complete: function () {
            $.spinner().stop();
        }
    });
};

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 */
function search(element) {
    $.spinner().start();

    var radius = 50;
    var country = $('#location-holder').data('country');

    const postalCode = element.find('input[name="postalCode"]').val();
    const urlParams = {
        radius: radius,
        isInventory: true,
        isCheckout: true,
        postalCode: postalCode,
        address: postalCode + ' ' + country
    };
    let url = element.attr('action'); // Stores-FindStoresStoreSelector

    ajaxStoreResults(url, urlParams);
}

/**
 * Display results by current location
 */
function detectLocation() {
    $.spinner().start();

    const $detectLocationButton = $('.detect-location');
    const radius = 50;

    const kioskStoreId = localStorage.getItem('kioskStoreId');
    if (kioskStoreId) {
        let url = $detectLocationButton.data('action-store-selector-kiosk');
        if (url) {
            const productID = $('.product-detail-page').data('pid');
            let urlParams = {
                radius: radius,
                kioskStoreId: kioskStoreId,
                isInventory: true,
                productID: productID,
                isCheckout: true
            };

            ajaxStoreResults(url, urlParams);
        }
    } else {
        if (!navigator.geolocation) {
            $.spinner().stop();
            return;
        }
        navigator.geolocation.getCurrentPosition(function (position) {
            var url = $detectLocationButton.data('action-store-selector');
            if (url) {
                var urlParams = {
                    isInventory: true,
                    radius: radius,
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                    isCheckout: true
                };

                ajaxStoreResults(url, urlParams);
            }
        }, function () {
            $.spinner().stop();
        });
    }
}

module.exports = {
    init: function () {
        getFavoriteStore();
        detectLocation();
    },

    detectLocation: function () {
        // clicking on detect location.
        $('body').on('click', 'form.store-pick-up-selector-form .detect-location', function (e) {
            e.preventDefault();
            detectLocation();
        });
    },

    search: function () {
        $('body').on('submit', 'form.store-pick-up-selector-form', function (e) {
            e.preventDefault();
            search($(this));
        });
    }
};
