'use strict';

var formValidation = require('../components/formValidation');
var Recaptcha = require('../util/recaptcha');
const Dialog = require('../util/dialog');

module.exports = {
    fillInEmail: function () {
        const email = $('#registration-form-email').val();

        if (email) {
            const emailInput = $('form.login').find('[name=loginEmail]');
            emailInput.val(email);
        }
    },
    loginClick: function () {
        $('form.login').on('submit', function (e) {
            e.preventDefault();

            var form = $(this);
            var url = $('.register-form-login').data('account-login-action');

            form.spinner().start();
            $(document).trigger('login:submit', {
                $form: form
            });

            Recaptcha.generateToken('register_login').then(function (token) {
                var requestData = form.serialize();
                requestData += '&g-recaptcha-response=' + token;

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: requestData,
                    success: function (data) {
                        form.spinner().stop();
                        if (!data.success) {
                            $(document).trigger('login:error', data);
                            formValidation(form, data);
                        } else {
                            $(document).trigger('login:success');
                            location.href = data.redirectUrl;
                        }
                    },
                    error: function (err) {
                        $(document).trigger('login:error', err);
                        form.spinner().stop();
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        }
                    }
                });
            }).catch(function () {
                $.spinner().stop();

                const errMsg = $('.error-messaging').data('uncaught-error-msg');
                const errTitle = $('.error-messaging').data('uncaught-error-title');
                Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                    accept: {
                        text: 'OK'
                    }
                });
            });

            return false;
        });
    }
};
