'use strict';

const formValidation = require('../components/formValidation');
const Dialog = require('../util/dialog');
const select = require('../components/select');
const Recaptcha = require('../util/recaptcha');
const Datepicker = require('../util/datepicker');

const closeKioskFamilyPopup = () => {
    const $modal = $('#safesizeModal');
    if ($modal && $modal.length) {
        $modal.modal('hide');
    }
};

const handleClosePopups = () => {
    $('#ravotterModal, #passportModal').on('hidden.bs.modal', function () {
        $(this).find('.modal-content').html('');
    });
};

const handlePassportListener = (e, $this, isRavot) => {
    e.preventDefault();
    $.spinner().start(true);
    $.ajax({
        url: $this.attr('href'),
        type: 'get',
        success: function (data) {
            closeKioskFamilyPopup();

            const modalSelector = isRavot ? '#ravotterModal' : '#passportModal';
            const $modal = $(modalSelector);

            $modal.find('.modal-content').html(data);
            $modal.modal('show');

            var $birthday = $modal.find('#birthday');
            if ($birthday && $birthday.length) {
                Datepicker.init(`${modalSelector} #birthday`);
            }

            if (isRavot) {
                require('../util/customSelectric').init();
            }

            select.init();
            $.spinner().stop();
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl && err.status === 403) {
                window.location.href = err.responseJSON.redirectUrl;
            }
            $.spinner().stop();
        }
    });
};

module.exports = {
    init: function () {
        $('body').on('input', '#passport-form-shoeSize, [id^="passport-form-shoeSize-"]', function () {
            var max = parseFloat($(this).attr('max'));
            var val = parseFloat($(this).val());

            if (val > max) {
                $(this).val(max);
            }
        });

        $('body').on('change', '#passport-form-shoeSize, [id^="passport-form-shoeSize-"]', function () {
            var min = parseFloat($(this).attr('min'));
            var val = parseFloat($(this).val());

            if (val < min) {
                $(this).val(min);
            }
        });
    },

    addPassPort: function () {
        $('body').on('click', '.passport-overview__button--add', function (e) {
            handlePassportListener(e, $(this), false);
        });
    },

    addPassPortRavotter: function () {
        $('body').on('click', '.passport-overview__button--add-ravotter', function (e) {
            handlePassportListener(e, $(this), true);
        });
    },

    editPassPort: function () {
        $('body').on('click', '.card__edit-link', function (e) {
            handlePassportListener(e, $(this), false);
        });
    },

    editPassPortRavotter: function () {
        $('body').on('click', '.card__edit-link-ravotter', function (e) {
            handlePassportListener(e, $(this), true);
        });
    },

    removePassPort: function () {
        $('body').on('click', '.remove-from-passport', function (e) {
            e.preventDefault();
            closeKioskFamilyPopup();
            const $this = $(this);

            const $container = $('.page-passport');
            const title = $container.data('d-remove-title');
            const confirm = $container.data('d-remove-confirm');
            const cancel = $container.data('d-remove-cancel');

            Dialog.show(Dialog.TYPE_ERROR, title, '', {
                cancel: {
                    text: cancel,
                    callback: function () {
                        const isKiosk = $this.parents('#safeSizeSearchPopup');
                        if (isKiosk && isKiosk.length) {
                            const $modal = $('#safesizeModal');
                            if ($modal && $modal.length) {
                                $modal.modal('show');
                            }
                        }
                    }
                },
                accept: {
                    text: confirm,
                    callback: function () {
                        Recaptcha.generateToken('account_removepassport').then(function (token) {
                            $.ajax({
                                url: $this.attr('href'),
                                data: 'g-recaptcha-response=' + token,
                                type: 'post',
                                success: function (data) {
                                    $this.closest('.passport-panel').remove();
                                    if (data.shouldRefresh) {
                                        location.reload();
                                    }
                                },
                                error: function (err) {
                                    if (err.responseJSON.redirectUrl && err.status === 403) {
                                        window.location.href = err.responseJSON.redirectUrl;
                                    }
                                }
                            });
                        }).catch(function () {
                            $.spinner().stop();

                            const errMsg = $('.error-messaging').data('uncaught-error-msg');
                            const errTitle = $('.error-messaging').data('uncaught-error-title');
                            Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                                accept: {
                                    text: 'OK'
                                }
                            });
                        });
                    }
                }
            }, null, null);
        });
    },

    handleChangeRavotMember: function () {
        $('body').on('change', '.js-ravotter-checkbox input', function () {
            var url = $(this).data('url'); // Account-ChangeRavotMember
            var checked = $(this).is(':checked');

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    member: checked
                },
                success: function (data) {
                    if (data.redirectUrl) {
                        location.href = data.redirectUrl;
                    }
                }
            });
        });
    },

    submitPassPortForm: function () {
        $('body').on('submit', 'form.passport-form', function (e) {
            let $form = $(this);

            let gender;
            var relation;
            if ($(this).hasClass('passport-form-ravotter')) {
                gender = $(this).find('.js-ravotter-gender-select').val();
            } else {
                gender = $("form.passport-form input[name='gender']:checked").val();
            }

            if ($(this).hasClass('passport-form-ravotter') || $(this).hasClass('isKiosk')) {
                // map relation for ravotter
                relation = parseInt(gender, 10) ? 'DAUGHTER' : 'SON';
                $(this).find('#relationtohead').val(relation);
            } else {
                relation = parseInt(gender, 10) ? 'WIFE' : 'HUSBAND';
                $(this).find('#relationtohead').val(relation);
            }

            $('#hiddengender').val(gender);

            let insole = $("form.passport-form input[name='insole']:checked").val();
            $('#hiddeninsole').val(insole);
            e.preventDefault();
            let url = $form.attr('action'); // Account-SubmitPassPortForm

            // Show thank you modal for pdp instead of redirect
            // added pd-ravotclub-header since this uses the same popups / functionality as the pdp
            if ($('.product-detail').length || $('.pd-ravotclub-header').length) {
                url += (url.indexOf('?') !== -1 ? '&' : '?') + 'pdp=true';
            }

            $.spinner().start();
            Recaptcha.generateToken('account_upsertpassport').then(function (token) {
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: $form.serialize() + '&g-recaptcha-response=' + token,
                    success: function (data) {
                        $.spinner().stop();
                        if (!data.success) {
                            formValidation($form, data);
                        } else if (data.pdp) {
                            $('#ravotterModal').modal('hide');
                            $('#ravotterThankyouModal').modal('show');
                        } else {
                            closeKioskFamilyPopup();
                            $('#ravotterModal').modal('hide');
                            if ($('.checkout').length) {
                                location.reload();
                            } else if (data.redirectUrl) {
                                location.href = data.redirectUrl;
                            } else {
                                $('#safeSizeLogin').trigger('click');
                            }
                        }
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        }
                        $.spinner().stop();
                    }
                });
            }).catch(function () {
                $.spinner().stop();

                const errMsg = $('.error-messaging').data('uncaught-error-msg');
                const errTitle = $('.error-messaging').data('uncaught-error-title');
                Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                    accept: {
                        text: 'OK'
                    }
                });
            });

            return false;
        });
    },

    handleClosePopups: handleClosePopups
};
