'use strict';

/**
 * updates the shippingMethod prices
 * @param {Array} totals - the totals data
 */
function updateShippingMethodPrices(totals) {
    const deliveryMomentprice = $('.delivery-moment-price');
    if (deliveryMomentprice.length) {
        deliveryMomentprice.text(totals.totalShippingCost);

        if (totals.shippingValue.value > 0) {
            deliveryMomentprice.text(totals.totalShippingCost);
            deliveryMomentprice.removeClass('free');
        } else {
            deliveryMomentprice.text(deliveryMomentprice.data('free'));
            deliveryMomentprice.addClass('free');
        }
    }
}

/**
 * updates the totals summary
 * @param {Array} totals - the totals data
 * @param {number} orderDiscount - the order discount amount
 * @param {number} bmCouponsDiscount - the bm coupons discount amount
 */
function updateTotals(totals, orderDiscount, bmCouponsDiscount) {
    $('.tax-total').text(totals.totalTax);
    $('.sub-total').text(totals.subTotal);
    $('.total-gross-price').text(totals.merchandizeTotalGrossPrice);
    $('.grand-total-sum').text(totals.grandTotal);

    if (totals.shippingValue.value > 0) {
        $('.shipping-total-cost-label').removeClass('shipping-total-cost-label--free');
        $('.shipping-total-cost').text(totals.totalShippingCost);
        $('.shipping-total-cost').removeClass('totals-price-free');
    } else {
        $('.shipping-total-cost-label').addClass('shipping-total-cost-label--free');
        $('.shipping-total-cost').text($('.shipping-total-cost').data('free'));
        $('.shipping-total-cost').addClass('totals-price-free');
    }

    // if (totals.orderLevelDiscountTotal.value > 0) {
    //     $('.order-discount').show();
    //     $('.order-discount-total').text('- ' + totals.orderLevelDiscountTotal.formatted);
    // } else {
    //     $('.order-discount').hide();
    // }

    if (totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').show();
        $('.shipping-discount-total').text('- '
            + totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').hide();
    }

    if (orderDiscount && orderDiscount > 0) {
        $('.order-discount').show();
        $('.order-discount-total').empty().append('- € ' + orderDiscount.toFixed(2).toString().replace('.', ','));
    } else {
        $('.order-discount').hide();
    }

    $('.order-price-without-discount').toggleClass('d-none', orderDiscount === 0 && bmCouponsDiscount === 0);

    if (totals.discountsHtml) {
        $('.coupons-and-promos').empty().append(totals.discountsHtml);
    }

    if (totals.BMCouponsHtml) {
        $('.bm-coupons').empty().append(totals.BMCouponsHtml);
    }

    if (totals.loyaltyDiscount) {
        $('.voucher-loyalty .totals-price').empty().append('- € ' + totals.loyaltyDiscount.toFixed(2).toString().replace('.', ','));
    }

    if (totals.grandTotalValue === 0) {
        $('.billing-not-free-col').addClass('d-none');
        $('.billing-free-col').removeClass('d-none');
    } else {
        $('.billing-not-free-col').removeClass('d-none');
        $('.billing-free-col').addClass('d-none');
    }
}

/**
 * updates the order product shipping summary for an order model
 * @param {Object} order - the order model
 */
function updateOrderProductSummaryInformation(order) {
    var $productSummary = $('<div />');
    order.shipping.forEach(function (shipping) {
        shipping.productLineItems.items.forEach(function (lineItem) {
            var pli = $('[data-product-line-item=' + lineItem.UUID + ']');
            $productSummary.append(pli);
        });
    });

    $('.product-summary-block').html($productSummary.html());
}

/**
 * updates the order tracking information
 * @param {Object} orderTracking - the order tracking model
 */
function updateOrderTrackingInformation(orderTracking) {
    $('meta.js-checkout-tracking').attr('content', JSON.stringify(orderTracking));
}

module.exports = {
    updateTotals: updateTotals,
    updateShippingMethodPrices: updateShippingMethodPrices,
    updateOrderProductSummaryInformation: updateOrderProductSummaryInformation,
    updateOrderTrackingInformation: updateOrderTrackingInformation
};
