'use strict';

var shippingHelpers = require('./shipping');
var summaryHelpers = require('./summary');
var formHelpers = require('../checkout/formErrors');

const addressBook = require('../addressBook/addressBook');
const CheckoutHelpers = require('./checkoutHelpers');
const mollieComponents = require('./components');
const processInclude = require('base/util');
const Slider = require('../util/slider');
const confirmationPopUp = require('../util/confirmationPopUp');
const appendToUrl = require('../util/appendToUrl');
const isMobile = require('../util/isMobile');
const Dialog = require('../util/dialog');
const addressUtils = require('../util/address');

var checkoutObj = {};

const mobileDevice = isMobile.mobileOrTablet();
const slides = mobileDevice ? 1.5 : 2.5;
const slidesPayment = mobileDevice ? 1.5 : 3.5;

/**
 * Shows warning message for BankTransfer (overschrijving)
 * To make sure the user selects a free shipping date since there is no guarantee that they can deliver on that date.
 */
checkoutObj.handleBanktransferWarning = () => {
    var $thisShowBTWarning = $('[name=dwfrm_billing_paymentMethod].showBTWarning');
    var title = $thisShowBTWarning.attr('data-dialog-remove-shipping-title');
    var message = $thisShowBTWarning.attr('data-dialog-remove-shipping-message');
    var confirm = $thisShowBTWarning.attr('data-dialog-remove-shipping-confirm');
    var cancel = $thisShowBTWarning.attr('data-dialog-remove-shipping-cancel');
    var url = $thisShowBTWarning.attr('data-url-remove-shipping-date'); // CheckoutShippingServices-RemoveDeliveryTime

    Dialog.show(Dialog.TYPE_ERROR, title, message, {
        accept: {
            text: confirm,
            callback: function () {
                $.ajax({
                    url: url, // CheckoutShippingServices-RemoveDeliveryTime
                    type: 'get',
                    dataType: 'json',
                    success: function () {
                        $thisShowBTWarning.removeClass('showBTWarning');
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        },
        cancel: {
            text: cancel,
            callback: function () { }
        }
    });

    $.spinner().stop();
};

/**
 * Adds params to CheckoutServices-PlaceOrder url
 * @param {string} url - Original URL
 * @returns {string} New url
 */
checkoutObj.appendPlaceOrderUrl = (url) => {
    let newUrl = url;
    let shouldLinkOrder = localStorage.getItem('shouldLinkOrder');
    if (shouldLinkOrder) {
        let shouldLinkOrderEmail = localStorage.getItem('shouldLinkOrderEmail');

        newUrl = appendToUrl(newUrl, {
            shouldLinkOrder: shouldLinkOrder,
            shouldLinkOrderEmail: shouldLinkOrderEmail
        });
    }

    let googleAnalyticsID = window.ga !== undefined && ga.getAll ? ga.getAll()[0].get('clientId') : null;
    if (googleAnalyticsID) {
        newUrl = appendToUrl(newUrl, {
            googleAnalyticsID: googleAnalyticsID
        });
    }

    return newUrl;
};

checkoutObj.handleDeliveryTime = (defer) => {
    let url = $('#checkout-main').data('check-delivery-time'); // CheckoutShippingServices-ValidateDeliveryTime

    $.ajax({
        url: url,
        type: 'get',
        success: function (data) {
            if (data.error) {
                defer.reject(data, 'user');
            } else {
                checkoutObj.handleShippingData(defer);
            }
        },
        error: function () {
            // Don't stop entire checkout for a edgecase issue that is manually fixable.
            checkoutObj.handleShippingData(defer);
        }
    });
};

checkoutObj.handlePlaceOrder = (defer) => {
    let url = $('#checkout-main').data('place-order-action');
    url = checkoutObj.appendPlaceOrderUrl(url);

    $.ajax({
        url: url, // CheckoutServices-PlaceOrder
        method: 'POST',
        success: function (placeOrderData) {
            if (placeOrderData.error) {
                if (placeOrderData.cartError) {
                    window.location.href = placeOrderData.redirectUrl;
                    defer.reject({ errMsg: placeOrderData.cartError }, 'system');
                } else {
                    defer.reject(placeOrderData, 'user');
                }
            } else {
                $('body').trigger('checkout:completeCustomCheckout');

                let continueUrl = placeOrderData.continueUrl;
                const renderQRCodeUrl = placeOrderData.renderQRCodeUrl;
                if (renderQRCodeUrl && renderQRCodeUrl.indexOf('xhr=true') >= 0 && !isMobile.mobileOrTablet()) {
                    $.get(renderQRCodeUrl, function (qrCodeHtml) {
                        $('body').append(qrCodeHtml);
                        $('#mollieQrCodeModal').modal('show');
                    });
                } else if (placeOrderData.orderID && placeOrderData.orderToken) {
                    var urlParams = {
                        ID: placeOrderData.orderID,
                        token: placeOrderData.orderToken
                    };
                    continueUrl += (continueUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(urlParams).map(function (key) {
                        return key + '=' + encodeURIComponent(urlParams[key]);
                    }).join('&');

                    window.location.href = continueUrl;
                } else {
                    window.location.href = continueUrl;
                }
                defer.resolve(placeOrderData);
            }
        }
    });

    localStorage.removeItem('shouldLinkOrder');
    localStorage.removeItem('shouldLinkOrderEmail');
};

checkoutObj.handleShippingData = (defer) => {
    // Clear Previous Errors
    formHelpers.clearPreviousErrors('.shipping-form');

    /* Submit the Shipping Address Form */
    var formSelector = '.shipping-form form';
    var form = $(formSelector);

    let gender = $(formSelector).find(".gender input[type='radio']:checked").val();
    $(formSelector).find('#hiddengender').val(gender);
    let accounttype = $(formSelector).find(".account-type input[type='radio']:checked").val();
    $(formSelector).find('#hiddenaccounttype').val(accounttype);

    $(formSelector).find("input[name*='phone']").each(function () {
        $(this).val($(this).val().trim().replace(/ /g, ''));
    });

    var shippingFormData = form.serialize();

    // Checks if there is an address
    var billingSameAsShippingToggle = $('.js-billing-toggle').length && $('.js-billing-toggle').is(':checked');
    var hasSelectedShipping = $('.shipping-address-information.selected').length;
    var hasSelectedBilling = $('.billing-address-information.selected').length;
    var isRegisteredCustomer = $('#checkout-main').attr('data-customer-type') === 'registered';

    if (((billingSameAsShippingToggle && !hasSelectedShipping) || (!billingSameAsShippingToggle && (!hasSelectedShipping || !hasSelectedBilling))) && isRegisteredCustomer) {
        confirmationPopUp.show({ title: form.attr('data-modal-no-address') }, confirmationPopUp.TYPE_ERROR);
        return defer.reject({ errMsg: form.attr('data-modal-no-address') }, 'user');
    }

    let formActionUrl = form.attr('action'); // CheckoutShippingServices-SubmitShipping

    const countryCode = $('.shipping-address-information').data('countrycode');
    if (countryCode) {
        formActionUrl = appendToUrl(formActionUrl, {
            countrycode: countryCode
        });
    }

    $.ajax({
        url: formActionUrl, // CheckoutShippingServices-SubmitShipping
        method: 'POST',
        data: shippingFormData,
        success: function (data) {
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            } else if (data.error) {
                let errors = []; // Used for loggin

                if (data.fieldErrors.length > 0) {
                    errors = data.fieldErrors;

                    data.fieldErrors.forEach(function (error) {
                        if (Object.keys(error).length) {
                            formHelpers.loadFormErrors(formSelector, error);
                        }
                    });
                }

                if (data.serverErrors.length > 0) {
                    errors = errors.concat(data.serverErrors);

                    data.serverErrors.forEach(function (error) {
                        CheckoutHelpers.showErrorDialog(error);
                    });
                }

                var template = data.popupTemplate;
                if (template) {
                    Dialog.show(Dialog.TYPE_CONFIRM, template.title, null, null, null, template.html, [require('../login/passwordReset').onEmailAllreadyExists], false);
                }

                $.spinner().stop();
                defer.reject({ errMsg: errors }, 'system');
            } else {
                shippingHelpers.methods.shippingFormResponse(defer, data);

                checkoutObj.handlePaymentData(defer);
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
            // Server error submitting form
            defer.reject(err.responseJSON, 'system');
            $.spinner().stop();
        }
    });

    return defer;
};

checkoutObj.ajaxSubmitPayment = (defer, paymentForm) => {
    $.ajax({
        url: $('#dwfrm_billing').attr('action'), // CheckoutServices-SubmitPayment
        method: 'POST',
        data: paymentForm,
        success: function (data) {
            // look for field validation errors
            if (data.error) {
                let errors = []; // Used for loggin

                if (data.fieldErrors.length) {
                    errors = data.fieldErrors;

                    data.fieldErrors.forEach(function (error) {
                        if (Object.keys(error).length) {
                            formHelpers.loadFormErrors('.payment-form', error);
                        }
                    });
                }

                if (data.serverErrors.length) {
                    errors = errors.concat(data.serverErrors);

                    data.serverErrors.forEach(function (error) {
                        CheckoutHelpers.showErrorDialog(error);
                    });
                }

                if (data.cartError) {
                    window.location.href = data.redirectUrl;
                }

                $.spinner().stop();
                defer.reject({ errMsg: errors }, 'system');
            } else {
                checkoutObj.handlePlaceOrder(defer);
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }

            defer.reject({ errMsg: err }, 'system');
            $.spinner().stop();
        }
    });
};

checkoutObj.handlePayment = (defer) => {
    formHelpers.clearPreviousErrors('.payment-form form');
    var paymentForm = $('#dwfrm_billing').serialize();

    // Make sure a payment method is selected
    if (!$('.billing-not-free-col').hasClass('d-none') && !$('[name=dwfrm_billing_paymentMethod]').is(':checked')) {
        confirmationPopUp.show($('.payment-form form').data('error'), confirmationPopUp.TYPE_ERROR);
        defer.reject({ errMsg: $('.payment-form form').data('error') }, 'user');
        return defer;
    }

    $('body').trigger('checkout:serializeBilling', {
        form: $('#dwfrm_billing'),
        data: paymentForm,
        callback: function (data) {
            paymentForm = data;
        }
    });

    if ($('#checkout-main').data('customer-type') === 'registered') {
        // if payment method is credit card
        if ($('.payment-information').data('payment-method-id') === 'CREDIT_CARD') {
            if (!($('.payment-information').data('is-new-payment'))) {
                var cvvCode = $('.saved-payment-instrument.selected-payment .saved-payment-security-code').val();

                if (cvvCode === '') {
                    $('.saved-payment-instrument.selected-payment .form-control').addClass('is-invalid');
                    defer.reject({ errMsg: 'no cvv code' }, 'user');
                    return defer;
                }

                var $savedPaymentInstrument = $('.saved-payment-instrument.selected-payment');

                paymentForm += '&storedPaymentUUID=' + $savedPaymentInstrument.data('uuid');
                paymentForm += '&securityCode=' + cvvCode;
            }
        }
    }

    checkoutObj.ajaxSubmitPayment(defer, paymentForm);
    return defer;
};

checkoutObj.handlePaymentData = (defer) => {
    if ($('.js-mollie-components-container').length
        && $('.js-mollie-component-tab').hasClass('active')
        && !$('.js-card-info').hasClass('checkout-hidden')) {
        mollieComponents.setCardToken().then(() => {
            checkoutObj.handlePayment(defer);
        }).catch((error) => {
            defer.reject({
                errorMessage: error
            }, 'system');
        });
    } else {
        checkoutObj.handlePayment(defer);
    }
};

checkoutObj.handleFailedSubmit = (defer) => {
    defer.fail(function (data, type) {
        // show errors
        if (data && data.errorMessage) {
            CheckoutHelpers.showErrorDialog(data.errorMessage, data.errorTitle);
        }

        let errMsg = data.errorMessage ? data.errorMessage : data.errMsg;
        errMsg = errMsg.title ? errMsg.title : errMsg;
        $('body').trigger('checkout:toolError', { msg: errMsg || '', type: type });

        $.spinner().stop();
    });
};

checkoutObj.handleSubmitCheckout = () => {
    $('body').on('click', 'button.next-step-button', function (e) {
        e.preventDefault();
        $.spinner().start();

        let defer = $.Deferred();

        if ($('[name=dwfrm_billing_paymentMethod]:checked.showBTWarning').length) {
            checkoutObj.handleBanktransferWarning();
        } else {
            checkoutObj.handleDeliveryTime(defer);
        }

        checkoutObj.handleFailedSubmit(defer);
    });
};

checkoutObj.adjustSliderTracks = ($tracks) => {
    // Handles slider styling when there are not enough items
    $tracks.each(function () {
        const $track = $(this);
        if ($track.children().length < slides) {
            $track.css({
                'margin-right': 'unset',
                'margin-left': 'unset'
            });
        }
    });
};

checkoutObj.slidersScrollTo = ($sliders) => {
    // scroll to selected element
    $sliders.each(function () {
        const $slider = $(this);
        const slide = $slider.find('.selected').parents('.slick-slide');
        const isLast = slide.is(':last-child');
        let slideNo = slide.data('slick-index');
        slideNo = !mobileDevice && isLast ? slideNo - 1 : slideNo;
        $slider.slick('slickGoTo', slideNo, true);
    });
};

checkoutObj.initSliders = () => {
    Slider.initializeSlider('.js-address-slider-container', slides, !mobileDevice, 1, null, null, mobileDevice, false);
    Slider.initializeSlider('.js-billingaddress-slider-container', slides, !mobileDevice, 1, null, null, mobileDevice, false);

    const $tracks = $('.js-address-slider-container .slick-track, .js-billingaddress-slider-container .slick-track');
    checkoutObj.adjustSliderTracks($tracks);

    const $sliders = $('.js-address-slider-container, .js-billingaddress-slider-container');
    checkoutObj.slidersScrollTo($sliders, mobileDevice);
};

checkoutObj.initPaymentSliders = () => {
    Slider.initializeSlider('.js-billingmethod-slider-container', slidesPayment, !mobileDevice, 1, null, null, mobileDevice, false);

    const $tracks = $('.js-billingmethod-slider-container .slick-track');
    checkoutObj.adjustSliderTracks($tracks);

    const $sliders = $('.js-billingmethod-slider-container');
    checkoutObj.slidersScrollTo($sliders, mobileDevice);
};

checkoutObj.initDeliveryOptionSliders = () => {
    Slider.initializeSlider('.js-shipping-method-slider', slides, !mobileDevice, 1, null, null, mobileDevice, false);
};

checkoutObj.initAllSliders = () => {
    checkoutObj.initSliders();
    checkoutObj.initPaymentSliders();
    checkoutObj.initDeliveryOptionSliders();
};

checkoutObj.selectHomeShipNbillAddr = (shipAddrID, billAddrID, cbChanged) => {
    $.ajax({
        url: $('.js-checkout-addresses').data('url'), // Checkout-SelectAddress
        type: 'get',
        data: {
            shippingAddress: shipAddrID,
            billingAddress: billAddrID,
            alsoBilling: $('input#same-billing-address').is(':checked'),
            cbChange: cbChanged
        },
        success: function (data) {
            $('.js-checkout-addresses').html(data.addressPickerHtml); // template: 'checkout/components/addressesContainer'
            $('.shipping-method-list').html(data.deliveryMethods.template);

            $('body').trigger('checkout:updateCheckoutView', { order: data.order });

            var $modal = $('#checkoutModal');
            if ($modal.length) {
                $modal.modal('hide');
            }

            checkoutObj.initSliders();
            checkoutObj.initDeliveryOptionSliders();
        },
        complete: () => {
            $.spinner().stop();
            $('#checkoutModal').off('hide.bs.modal');
        }
    });
};

checkoutObj.handleAddressChange = () => {
    $('body').on('change', 'input#same-billing-address', function () {
        $.spinner().start();

        checkoutObj.selectHomeShipNbillAddr(null, null, true);
    });

    $('body').on('click keyup', '.js-select-address:not(.selected)', function (event) {
        if (event.type !== 'click' && event.key !== 'Enter') return;

        $.spinner().start();
        const addrID = $(this).data('addr-id');
        if ($(this).hasClass('shipping-address-information')) {
            checkoutObj.selectHomeShipNbillAddr(addrID, null, false);
        } else if ($(this).hasClass('billing-address-information')) {
            checkoutObj.selectHomeShipNbillAddr(null, addrID, false);
        }
    });
};

checkoutObj.handleStoreAddressChange = () => {
    $('body').on('click keyup', '.js-select-store-address:not(.selected)', function (event) {
        if (event.type !== 'click' && event.key !== 'Enter') return;

        const $selectedStore = $(this);
        let selectedStoreId = $(this).data('store-id');
        let url = $(this).parents('.js-address-slider-container').data('set-selected-store-id-url');
        let setSelectedStoreUrlParams = {
            selectedStoreId: selectedStoreId,
            newlyChosenId: false
        };
        url = appendToUrl(url, setSelectedStoreUrlParams);
        $.ajax({
            url: url, // Checkout-SetSelectedStoreId
            type: 'post',
            success: function () {
                const $storeAddrCard = $('.js-select-store-address');
                $storeAddrCard.removeClass('selected');
                $selectedStore.addClass('selected');
            }
        });
    });
};

checkoutObj.initSaveNewAddress = () => {
    $('body').on('click', '.add-address', function (e) {
        e.preventDefault();

        const isBilling = $(this).parents('.billing-address').length;
        let url = $(this).attr('href'); // Address-AddAddress
        url += '?checkout=true';

        addressBook.showFormPopUp(url, 'title-add', '#checkoutModal');

        // shouldnt really be completely necessary as the save button already updates current address
        // we should only provide an updated addressList
        $('#checkoutModal').on('hide.bs.modal', function () {
            var addressID = $('#checkoutModal #addressId').val();
            if (addressID) {
                if (isBilling) {
                    checkoutObj.selectHomeShipNbillAddr(null, addressID, false);
                } else {
                    checkoutObj.selectHomeShipNbillAddr(addressID, null, false);
                }
            }
        });
    });
};

checkoutObj.changeAddressHandler = (event, isBilling, $this) => {
    event.preventDefault();

    const addressType = isBilling ? 'billing' : 'shipping';
    const addressID = $this.parents(`.${addressType}-address-information`).data('addr-id');

    let params = `?saveToBasketAddress=${addressType}`;
    params += '&addressId=' + addressID;
    params += '&alsoBilling=' + $('input#same-billing-address').is(':checked');

    let url = $(event.currentTarget).attr('href'); // Address-EditAddress
    url += params;

    addressBook.showFormPopUp(url, 'title-edit', '#checkoutModal');

    // shouldnt really be completely necessary as the save button already updates current address
    // we should only provide an updated addressList
    $('#checkoutModal').on('hide.bs.modal', function () {
        if (isBilling) {
            checkoutObj.selectHomeShipNbillAddr(null, addressID, false);
        } else {
            checkoutObj.selectHomeShipNbillAddr(addressID, null, false);
        }
    });
};

checkoutObj.initChangeAddress = () => {
    $('body').on('click', '.billing-address .change-address', function (e) {
        checkoutObj.changeAddressHandler(e, true, $(this));
    });

    $('body').on('click', '.shipping-address .change-address', function (e) {
        checkoutObj.changeAddressHandler(e, false, $(this));
    });
};

checkoutObj.initPaymentFunctions = () => {
    // load correct images
    // $('#stored-methods img').each(function () {
    //     $(this).attr('src', $(`#image-${$(this).data('image-id')}`).attr('src'));
    // });

    $('body').on('click', '.payment-option-container', function () {
        $('.payment-option-container.selected').removeClass('selected');
        $(this).find('input').prop('checked', true);
        $(this).addClass('selected');
    });
};

checkoutObj.removeCoupon = () => {
    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();
        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var url = $(this).data('action');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).fadeOut(function () {
                    $(this).remove();
                });
                summaryHelpers.updateTotals(data.totals, data.savings, data.bmCouponsTotal);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                $('.bm-coupons').empty().append(data.totals.BMCouponsHtml);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $.spinner().stop();
                }
            }
        });
    });
};

checkoutObj.removeVoucher = () => {
    $('body').on('click', '.remove-voucher', function (e) {
        e.preventDefault();
        const voucherCode = $(this).data('voucher-code');
        let url = $(this).data('remove-voucher-url');
        url = appendToUrl(url, {
            voucherCode: voucherCode
        });
        $.ajax({
            url: url,
            type: 'post',
            success: function (data) {
                summaryHelpers.updateTotals(data.totals, data.savings, data.bmCouponsTotal);
                $(`.voucher-${voucherCode}`).fadeOut(function () {
                    $(this).remove();
                });
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                $('.bm-coupons').empty().append(data.totals.BMCouponsHtml);
            }
        });
    });
};

checkoutObj.submitPromoCode = () => {
    $('.promo-code-submit .btn').on('click', function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form :input');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        let url = $('.checkout-coupon-code').data('add-coupon-url');
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    $('.bm-coupons').empty().append(data.totals.BMCouponsHtml);
                    summaryHelpers.updateTotals(data.totals, data.savings, data.bmCouponsTotal);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            }
        });
        return false;
    });
};

checkoutObj.updateCheckoutView = () => {
    $('body').on('checkout:updateCheckoutView', function (e, data) {
        summaryHelpers.updateTotals(data.order.totals, data.order.savings, data.bmCouponsTotal);
        summaryHelpers.updateOrderProductSummaryInformation(data.order, data.options);
        summaryHelpers.updateOrderTrackingInformation(data.order.orderTracking);
        summaryHelpers.updateShippingMethodPrices(data.order.totals);

        checkoutObj.imageZoom();
    });
};

checkoutObj.handlePostalCodeInput = () => {
    addressUtils.changePostalCodeInputTypeOnMobile($('#shipping-address-form-country'), $('#shipping-address-form-postalCode'));
    $('#shipping-address-form-country').on('change', function () {
        addressUtils.changePostalCodeInputTypeOnMobile($('#shipping-address-form-country'), $('#shipping-address-form-postalCode'));
    });
};

checkoutObj.imageZoom = () => {
    $('.js-summary-zoom').magnificPopup({
        type: 'image'
    });
};

checkoutObj.init = () => {
    mollieComponents.init();

    checkoutObj.initChangeAddress();
    checkoutObj.initSaveNewAddress();
    checkoutObj.initAllSliders();
    checkoutObj.handleAddressChange();
    checkoutObj.handleStoreAddressChange();
    checkoutObj.handleSubmitCheckout();
    checkoutObj.initPaymentFunctions();
    checkoutObj.removeCoupon();
    checkoutObj.removeVoucher();
    checkoutObj.submitPromoCode();
    checkoutObj.updateCheckoutView();
    checkoutObj.handlePostalCodeInput();
    checkoutObj.imageZoom();

    processInclude(require('base/checkout/address'));
    processInclude(require('./shipping'));
    processInclude(require('./billing'));
};

module.exports = checkoutObj;
